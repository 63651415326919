angular.module('llax.services')
    .service('GroupAttributeService', function($filter, $rootScope, $parse, $sce, MultiDimensionalAttributeService) {

        var self = this;

        self.initializeScope = function($scope) {
            self.$scope = $scope;
            $scope.groupStructure = [];
            $scope.groupMemberAttributes = [];
            $scope.showHideGroupData = [];
            $scope.groupAttributeError = [];
            $scope.validationsGroupItem = {};

            $scope.getModel = self.getModel;
            $scope.isGroupRendered = self.isGroupRendered;
            $scope.isGroupEmpty = self.isGroupEmpty;
            $scope.isGroupRenderedEntityEmpty = self.isGroupRenderedEntityEmpty;
            $scope.getVisibleMemberAttributes = self.getVisibleMemberAttributes;
            $scope.initGroupAttribute = self.initGroupAttribute;
            $scope.showGroupData = self.showGroupData;
            $scope.initGroup = self.initGroup;
            $scope.initEntry = self.initEntry;
            $scope.getEntryTitle = self.getEntryTitle;
            $scope.newGroup = self.newGroup;
            $scope.addGroup = self.addGroup;
            $scope.groupTitleClick = self.groupTitleClick;
            $scope.copyGroup = self.copyGroup;
            $scope.deleteGroup = self.deleteGroup;
            $scope.clearGroup = self.clearGroup;
            $scope.groupEven = self.groupEven;
            $scope.isGroup = self.isGroup;
            $scope.getGroupTitleError = self.getGroupTitleError;
            $scope.getGroupTitleWarning = self.getGroupTitleWarning;
            $scope.getGroupValidationMessages = self.getGroupValidationMessages;
            $scope.hasGroupValidationMessages = self.hasGroupValidationMessages;
            $scope.checkGroupValidations = self.checkGroupValidations;
            $scope.checkGroupValidation = self.checkGroupValidation;
            $scope.isGroupKeyAttribute = self.isGroupKeyAttribute;
            $scope.checkGroupLocalValidations = self.checkGroupLocalValidations;
            $scope.getLocalValidationErrors = self.getLocalValidationErrors;
            $scope.hasLocalValidationErrors = self.hasLocalValidationErrors;
            $scope.registerLocalValidationsWatcher = self.registerLocalValidationsWatcher;
        };

        self.getModel = function(attribute, parentId, parentAttribute) {
            var string = "item";
            var pathString = "";
            var createPath = function (attr, parentId) {
                var entry = _.find(self.$scope.groupStructure, {
                    'attribute': attr
                });
                pathString = "['" + attr.name + "'][" + getIndex(entry, parentId, entry.parentAttribute) + "]" + pathString;
                if (entry.level > 0) {
                    var indexEntry = _.find(self.$scope.groupIndexes, {
                        'id': parentId
                    });
                    createPath(entry.parentAttribute, indexEntry.parentId);
                }
            };
            createPath(parentAttribute, parentId);

            string = string + pathString + "['" + attribute.name + "']";
            return string;
        };

        function getIndex(groupEntry, parentId, parentAttribute) {
            //look up containing group attribute and level and then get index
            var entry = _.find(self.$scope.groupIndexes, {
                'id': parentId
            });
            var index = entry ? entry.__index__ : -1;

            return index;
        }

        self.isGroupRendered = function(attribute) {
            return _.isEqual(attribute.params.rendererParams.type, 'Group');
        };

        self.isGroupEmpty = function(attribute) {
            if (_.isEqual(attribute.typeName, 'Group')) {
                return _.isEmpty(self.$scope.item[attribute.name]);
            }
            return false;
        };

        self.isGroupRenderedEntityEmpty = function(attribute) {
            if (_.isEqual(attribute.params.rendererParams.type, 'Group')) {
                return _.isEmpty(self.$scope.item[attribute.name]);
            }
            return false;
        };

        self.getVisibleMemberAttributes = function (attribute) {
            // Only non-hidden attributes
            var memberAttributes = self.$scope.dataModel.getMemberAttributes(attribute);
            memberAttributes = _.filter(memberAttributes, function (memberAttribute) {
                return !self.$scope.isAttributeHidden(memberAttribute);
            });
            return memberAttributes;
        };

        self.initGroupAttribute = function (attribute, groupAttribute) {
            //the attribute could be a `string` because of the filter : dynamicFilter
            if (_.isString(attribute)) {
                attribute = self.$scope.dataModel.getMemberAttributes(attribute);
            }
            $rootScope.prepareAttribute(attribute);
            if (attribute) {
                attribute.isComplexType = (attribute.typeName == 'Collection' || attribute.typeName ==
                    'MultiDimensional' || attribute.typeName == 'MultiReference');
            }
            return attribute;
        };

        self.showGroupData = function (attributeName, parentId, index) {

            var show = true;
            var entry = _.find(self.$scope.showHideGroupData[attributeName], {
                'parentId': parentId,
                '__index__': index
            });
            if (entry) {
                show = entry.show;
            }
            return show;
        };

        function isGroupOrGroupRendered(attribute) {
            if (attribute.typeName === 'Group' || attribute.params.renderer === 'Group') {
                return true;
            } else {
                return false;
            }
        }

        self.initGroup = function (attribute, parentId) {

            var $scope = self.$scope;
            var checkGroupLevels = [];
            var attributeMembers = [];
            var groupIsReadOnly = [];
            $scope.groupMemberAttributes[attribute.name] =
                $scope.groupMemberAttributes[attribute.name] ? $scope.groupMemberAttributes[attribute.name]: [];
            checkGroupLevels[attribute.name] = false;
            attributeMembers[attribute.name] = self.getVisibleMemberAttributes(attribute);
            groupIsReadOnly[attribute.name] = $scope.isAttributeReadonly(attribute);

            _.forEach(attributeMembers[attribute.name], function (member) {
                var a = member;
                $rootScope.setInputRenderer(a);
                $scope.groupMemberAttributes[attribute.name].push(a);
                if (isGroupOrGroupRendered(a)) {
                    checkGroupLevels[attribute.name] = true;
                }
                if (groupIsReadOnly[attribute.name] && !$scope.isAttributeHidden(a)) {
                    $scope.attributeStates[a.name] = ["readonly"];
                }
            });

            var groupEntry = _.find($scope.groupStructure, {
                'attribute': attribute
            });

            if (groupEntry && groupEntry.level > 0) {
                return;
            }

            if (!groupEntry && !checkGroupLevels[attribute.name]) {
                if (!$scope.item[attribute.name]) {
                    $scope.item[attribute.name] = [];
                }
                $scope.groupStructure.push({
                    'attribute': attribute,
                    'parentAttribute': undefined,
                    'level': 0
                });
            }

            //check if group contains other group
            if (checkGroupLevels[attribute.name] && !groupEntry) {
                var level = 0;
                var checkGroup = function (containingAttribute) {
                    if(level == 0) {
                        $scope.groupStructure.push({
                            'attribute': containingAttribute,
                            'parentAttribute': undefined,
                            'level': level
                        });
                    }

                    var attributeMembers = self.getVisibleMemberAttributes(containingAttribute);
                    _.forEach(attributeMembers, function (member) {
                        $rootScope.setInputRenderer(member);
                        if (isGroupOrGroupRendered(member)) {
                            level++;
                            $scope.groupStructure.push({
                                'attribute': member,
                                'parentAttribute': containingAttribute,
                                'level': level
                            });
                            checkGroup(member);
                        }
                    });
                };
                checkGroup(attribute);
            }
        };

        self.initEntry = function (attribute, id, index, parentId) {

            var $scope = self.$scope;
            if (!$scope.groupIndexes) {
                $scope.groupIndexes = [];
            }
            $scope.groupIndexes.push({
                'id': id,
                '__index__': index,
                'parentId': parentId
            });
        };

        self.getEntryTitle = function(attribute, model, index) {

            var $scope = self.$scope;
            var filterName = 'defaultGroupTitleFilter';
            var filterParameters = [];

            // Override the 'defaultGroupTitleFilter' if there is one defined in the data model.
            if (!_.isNil(attribute.params.uiGroupTitleFilter)) {
                var uiGroupTitleFilter = attribute.params.uiGroupTitleFilter;
                if (_.isArray(uiGroupTitleFilter)) {
                    uiGroupTitleFilter = _.chain(uiGroupTitleFilter)
                        .keyBy('key')
                        .mapValues('value')
                        .value();
                    filterName = uiGroupTitleFilter.filter_name;
                    filterParameters = uiGroupTitleFilter.filter_parameters;
                } else if (_.isString(uiGroupTitleFilter)) {
                    filterName = uiGroupTitleFilter;
                } else {
                    $log.warn('Could not parse group title filter', attribute);
                }
            }

            var entry = null;
            if (_.isNil(model)) {
                entry = $scope.item[attribute.name][index];
            } else {
                // In case of nested entries.
                entry = $parse(model)($scope)[index];
            }

            var title = $filter(filterName)(entry, index, filterParameters, attribute, $scope.item, $rootScope.user, $rootScope.organization);
            return $sce.trustAsHtml(title);
        };

        self.newGroup = function(attribute, parentId) {

            var $scope = self.$scope;
            var populateGroup = function(attribute){
                var entryTmp = {};
                var attributeMembers = self.getVisibleMemberAttributes(attribute);
                _.forEach(attributeMembers,function(member){
                    if(member.typeName == 'Group'){
                        entryTmp[member.name] = [];
                    } else {
                        entryTmp[member.name] = undefined;
                    }
                });
                return entryTmp;
            };

            var entry = populateGroup(attribute);
            entry.__index__ = 1;

            //identify at what recursion level store the value
            var group = _.find($scope.groupStructure, {
                'attribute': attribute
            });
            if(group){
                var level = group ? group.level : 0;
                if(level <= 0){
                    if(!$scope.item[attribute.name]){
                        $scope.item[attribute.name] = [];
                    }
                    $scope.item[attribute.name].push(entry);

                } else {
                    var model = $parse($scope.getModel(attribute, parentId, group.parentAttribute));
                    var tmp = [];
                    tmp.push(entry);
                    model.assign($scope, tmp);
                }
            }
        };

        self.addGroup = function (attribute, parentId, index) {

            var $scope = self.$scope;
            var newEntry = {};
            var attributeMembers = self.getVisibleMemberAttributes(attribute);
            _.forEach(attributeMembers, function (member) {
                newEntry[member.name] = undefined;
            });

            //identify at what recursion level store the value
            var group = _.find($scope.groupStructure, {
                'attribute': attribute
            });
            var level = group ? group.level : 0;

            if (level <= 0) {
                //to-do: adding in between
                index = $scope.item[attribute.name].length;
                newEntry.__index__ = index + 1;

                $scope.item[attribute.name].splice(index + 1, 0, newEntry);
            } else {
                var modelString = $scope.getModel(attribute, parentId, group.parentAttribute);
                var tmp = $scope.$eval(modelString);
                var model = $parse(modelString);

                //to-do: adding in between
                index = tmp.length;

                tmp.splice(index + 1, 0, newEntry);
                model.assign($scope, tmp);
            }
        };

        self.groupTitleClick = function (attribute, parentId, index) {

            var $scope = self.$scope;
            var show = true;
            var entry = _.find($scope.showHideGroupData[attribute.name], {
                'parentId': parentId,
                '__index__': index
            });
            if (!entry) {
                if (!$scope.showHideGroupData[attribute.name]) {
                    $scope.showHideGroupData[attribute.name] = [];
                }
                $scope.showHideGroupData[attribute.name].push({
                    'parentId': parentId,
                    '__index__': index,
                    show: false
                });
                show = false;
            } else {
                show = entry.show;
                entry.show = !entry.show;
            }
            return show;
        };

        self.copyGroup = function (attribute, parentId, prevEntry, index) {

            var $scope = self.$scope;
            var newEntry = {};
            _.forEach(prevEntry, function (value, key) {
                newEntry[key] = _.cloneDeep(value);
            });
            //identify at what recursion level store the value
            var group = _.find($scope.groupStructure, {
                'attribute': attribute
            });
            var level = group ? group.level : 0;

            if (level <= 0) {
                //to-do: adding in between
                index = $scope.item[attribute.name].length;

                $scope.item[attribute.name].splice(index + 1, 0, newEntry);
            } else {
                var modelString = $scope.getModel(attribute, parentId, group.parentAttribute);
                var tmp = $scope.$eval(modelString);
                var model = $parse(modelString);

                //to-do: adding in between
                index = tmp.length;

                tmp.splice(index + 1, 0, newEntry);
                model.assign($scope, tmp);
            }
        };

        function deleteGroup($scope, attribute, entry, parentId, index) {

            //identify at what recursion level delete the value
            var group = _.find($scope.groupStructure, {
                'attribute': attribute
            });
            var level = group ? group.level : 0;

            if (level <= 0) {
                _.remove($scope.item[attribute.name], function (value, i, array) {
                    return i == index;
                });
            } else {
                var modelString = $scope.getModel(attribute, parentId, group.parentAttribute);
                var tmp = $scope.$eval(modelString);
                var model = $parse(modelString);
                _.remove(tmp, function (value, i, array) {
                    return i == index;
                });
                model.assign($scope, tmp);
            }
            var showHide = _.find($scope.showHideGroupData[attribute.name], {
                'parentId': parentId,
                '__index__': index
            });
            _.remove($scope.showHideGroupData[attribute.name], showHide);
        }

        self.deleteGroup = function (attribute, entry, parentId, index) {

            var $scope = self.$scope;
            if (!$scope.disableDeleteConfirmation) {
                $scope.removeAttributeValueConfirmation(attribute,function() {
                    deleteGroup($scope, attribute, entry, parentId, index);
                });
            } else {
                deleteGroup($scope, attribute, entry, parentId, index);
            }
        };

        function clearAttribute($scope, attributeName, reference){

            var attribute = $scope.dataModel.attribute(attributeName);

            if ($scope.isAttributeReadonly(attribute)) {
                return;
            }
            // We cannot use 'delete' here, because then the ItemChangesQueues would not detect any changes!
            var previousValue = reference[attributeName];
            if (_.isArray(previousValue)) {
                    reference[attributeName] = [];
                if ($scope.gridOptionsMap[attributeName]) {
                    $scope.gridOptionsMap[attributeName].minRowsToShow = 1;
                }
            } else {
                reference[attributeName] = null;
            }
            //to-do:check if this works with group attribute?
            if (attribute.typeName === 'AdditionalCategory' && !_.isNil(previousValue)) {
                $scope.updateAdditionalCategoryAttributes($scope.item, [attributeName]);
            }
            $scope.$broadcast('clearAttributeValue', attributeName);
        }

        self.clearGroup = function(attribute, entry, index) {
            var $scope = self.$scope;
            var attributeMembers = $scope.dataModel.attribute(attribute.params.valueAttribute).members;
            _.forEach(attributeMembers,function(member){
                clearAttribute($scope, member,entry);
            });
        };

        self.groupEven = function (attribute) {

            var $scope = self.$scope;
            var group = _.find($scope.groupStructure, {
                'attribute': attribute
            });
            var level = group ? group.level : 0;
            level = level + 1;

            if (level % 2 == 0) {
                return true;
            }
            return false;
        };

        self.isGroup = function(attribute) {
            return _.isEqual(attribute.typeName, 'Group');
        };

        function filterGroupPath($scope, path) {

            var includesDimensional = false;
            var newPath = _.filter(path, function (o) {
                if (includesDimensional) {
                    includesDimensional = false;
                    return false;
                }
                var type = $scope.dataModel.attribute(o) ? $scope.dataModel.attribute(o).typeName : null;
                if (type == "Composite") {
                    return false;
                } else if (type == "Dimensional") {
                    includesDimensional = true;
                    return true;
                } else {
                    return true;
                }

            });
            newPath = _.forEach(newPath, function (o, index) {
                if (_.isNumber(o)) {
                    newPath[index] = o.toString();
                }
            });

            return newPath;
        }

        function getValidationsModel($scope, attribute, id, parentId, currentIndex) {

            var groupEntry = _.find($scope.groupIndexes, {
                'id': id
            });
            var groupIndex = groupEntry ? groupEntry.__index__ : -1;
            var groupAttributeEntry = _.find($scope.groupStructure, {
                'attribute': attribute
            });
            groupLevel = groupAttributeEntry ? groupAttributeEntry.level : -1;

            var model = "item[" + attribute.name + "][" + groupIndex + "]";
            if (groupLevel > 0) {
                model = $scope.getModel(attribute, parentId, groupAttributeEntry.parentAttribute) + "[" + currentIndex + "]";
            }
            return model;
        }

        function getPathToCheck(model) {
            var shortModel = model.slice(4);
            var pathToCheck = shortModel.match(/\[(.*?)\]/g);
            pathToCheck = _.forEach(pathToCheck, function (o, index) {
                pathToCheck[index] = _.replace(o.slice(1, -1), /\'/g, "");
            });
            return pathToCheck;
        }

        self.getGroupTitleError = function (attribute, id, parentId, currentIndex) {

            var $scope = self.$scope;
            var model = getValidationsModel($scope, attribute, id, parentId, currentIndex);
            var validations = $scope.validations;
            var count = 0;
            if (validations) {
                _.forEach(validations, function (validation) {
                    _.forEach(validation, function (validationEntry) {
                        if (validationEntry.level == "Error" || validationEntry.level === 'Failure') {
                            var pathToCheck = getPathToCheck(model);
                            var path = filterGroupPath($scope, validationEntry.path);
                            var pathIncluded = pathToCheck.length;
                            _.forEach(pathToCheck, function (entry, index) {
                                if (entry == path[index]) {
                                    pathIncluded--;
                                }
                            });
                            if (pathIncluded < 1) {
                                count++;
                            }
                        }
                    });
                });
            }
            return count;
        };

        self.getGroupTitleWarning = function (attribute, id, parentId, currentIndex) {

            var $scope = self.$scope;
            var model = getValidationsModel($scope, attribute, id, parentId, currentIndex);
            var validations = $scope.validations;
            var count = 0;
            if (validations) {
                _.forEach(validations, function (validation) {
                    _.forEach(validation, function (validationEntry) {
                        if (validationEntry.level == "Warning") {
                            var pathToCheck = getPathToCheck(model);
                            var path = filterGroupPath($scope, validationEntry.path);
                            var pathIncluded = pathToCheck.length;
                            _.forEach(pathToCheck, function (entry, index) {
                                if (entry == path[index]) {
                                    pathIncluded--;
                                }
                            });
                            if (pathIncluded <= 1) {
                                count++;
                            }
                        }

                    });
                });
            }
            return count;
        };

        self.hasGroupValidationMessages = function (index) {
            var $scope = self.$scope;
            if ($scope.validationsGroupItem[index]) {
                return ($scope.validationsGroupItem[index].errors.length > 0 ||
                        $scope.validationsGroupItem[index].failures.length > 0 ||
                        $scope.validationsGroupItem[index].warnings.length > 0);
            }
            return false;
        };

        self.getGroupValidationMessages = function (attribute, id, parentId, currentIndex) {
            var $scope = self.$scope;
            var validationErrors = [];
            var validationFailures = [];
            var validationWarnings = [];

            var groupEntry = _.find($scope.groupIndexes, {
                'id': id
            });
            var groupIndex = groupEntry ? groupEntry.__index__ : -1;
            var group = _.find($scope.groupStructure, {
                'attribute': attribute
            });
            var groupLevel = group.level;
            var model = "item[" + attribute.name + "][" + groupIndex + "]";
            if (groupLevel > 0) {
                model = $scope.getModel(attribute, parentId, group.parentAttribute) + "[" + currentIndex + "]";
            }

            var validations = $scope.validations;
            var validationsGroupItem = $scope.validationsGroupItem;
            if (validations) {
                _.forEach(validations, function (validation) {
                    _.forEach(validation, function (validationEntry) {
                        var pathToCheck = getPathToCheck(model);
                        path = filterGroupPath($scope, validationEntry.path);
                        var pathIncluded = pathToCheck.length;
                        _.forEach(pathToCheck, function (entry, index) {
                            if (entry == path[index]) {
                                pathIncluded--;
                            }
                        });
                        if (pathIncluded < 1) {
                            var validationName = attribute.name + ":" + validationEntry.name;
                            var validationLabel = (validation.translatedLabel || $rootScope.translateValidationLabel(validationName));
                            var message = validationLabel.replace(attribute.name, path[path.length - 1]);
                            var validationObject = {
                                message: message,
                                parentId: parentId,
                                model: model,
                                path: path
                            };
                            if (validationEntry.level === 'Error') {
                                validationErrors.push(validationObject);
                            } else if (validationEntry.level === 'Failure') {
                                validationFailures.push(validationObject);
                            } else if (validationEntry.level === 'Warning') {
                                validationWarnings.push(validationObject);
                            }
                        }
                    });
                });
            }

            if (!validationsGroupItem[currentIndex]) {
                validationsGroupItem[currentIndex] = {
                    errors: [],
                    failures: [],
                    warnings: []
                };
            }

            // Append the current validation results to the parent scope object if they do not already exist
            validationErrors.forEach(function(error) {
                if (!_.some(validationsGroupItem[currentIndex].errors, error)) {
                    validationsGroupItem[currentIndex].errors.push(error);
                }
            });

            validationFailures.forEach(function(failure) {
                if (!_.some(validationsGroupItem[currentIndex].failures, failure)) {
                    validationsGroupItem[currentIndex].failures.push(failure);
                }
            });

            validationWarnings.forEach(function (warning) {
                // console.log('warning', warning, validationsGroupItem[entryIndex]);
                if (!_.some(validationsGroupItem[currentIndex].warnings, warning)) {
                    validationsGroupItem[currentIndex].warnings.push(warning);
                }
            });

            function formatValidationMessages(validationList, type) {
                return validationList.map(function (validation) {
                    return "<li class='" + type + "'>" + validation.message + "</li>";
                }).join('');
            }

            var validationErrorsHtml = formatValidationMessages(validationErrors, 'error');
            var validationFailuresHtml = formatValidationMessages(validationFailures, 'failure');
            var validationWarningsHtml = formatValidationMessages(validationWarnings, 'warning');

            return "<ul>" + validationErrorsHtml + validationFailuresHtml + validationWarningsHtml + "\n</ul>";

        };

        self.checkGroupValidations = function (parentAttribute, model) {

            var $scope = self.$scope;
            var validations = $scope.validations;
            $scope.groupAttributeError[model] = false;
            if (validations) {
                var included = false;
                _.forEach(validations, function (validation) {
                    _.forEach(validation, function (validationEntry) {
                        _.forEach(validationEntry.path, function (entry) {
                            if (entry == parentAttribute.name) {
                                included = true;
                                return;
                            }
                        });
                    });
                    if (included) {
                        return;
                    }
                });

                if (included) {
                    return true;
                } else {
                    return false;
                }
            }
            return false;
        };

        self.checkGroupValidation = function (validation, model) {

            var $scope = self.$scope;
            if (validation) {
                var path = validation.path;
                var shortModel = model.slice(4);
                var pathToCheck = shortModel.match(/\[(.*?)\]/g);
                pathToCheck = _.forEach(pathToCheck, function (o, index) {
                    pathToCheck[index] = _.replace(o.slice(1, -1), /\'/g, "");
                });

                path = filterGroupPath($scope, path);

                var groupHasError = true;
                _.forEach(pathToCheck, function (v, index) {
                    if (pathToCheck[index] !== path[index]) {
                        groupHasError = false;
                    }
                });

                if(groupHasError) {
                    $scope.groupAttributeError[model] = true;
                }

                return groupHasError;

            }
            return false;
        };

        self.registerLocalValidationsWatcher = function($scope, attribute) {

            // Local validations are only supported on attributes which has MultiDimensional as base attribute, therefore
            // we will only register the watch on those attributes, in order to not hit the perf too much.
            if (attribute.typeName != 'MultiDimensional') {
                return;
            }

            var editorScope = self.$scope;
            var model = '';
            var group = _.find(editorScope.groupStructure, {
                'attribute': attribute
            });

            if (group.level <= 0) {
                model = 'item["' + attribute.name + '"]';
            } else {
                model = $scope.$parent.model;
            }

            editorScope.$watch(model, function(newValue) {
                self.checkGroupLocalValidations(attribute, model, newValue);
            }, true);

        };

        self.checkGroupLocalValidations = function(groupAttribute, path, groupValues) {

            var editorScope = self.$scope;

            // Map the group structure to the structure of a multi dimensional collection
            var rows = _.map(groupValues, function(entry) {
                return {
                    entity: entry
                };
            });

            var attributeErrors = _.reduce(rows, function(result, entry) {

                MultiDimensionalAttributeService.validateMultiDimensionalEntry(entry, rows, groupAttribute);

                if (!_.isEmpty(entry.errors)) {
                    var entryErrors = _.uniq(result.errors.concat(entry.errors));
                    result = {
                        attribute: result.attribute,
                        errors: entryErrors
                    };
                }

                return result;
            }, { attribute: groupAttribute, errors: [] });

            editorScope.$emit('addLocalValidation', { path: path, attributeErrors: attributeErrors });

        };

        self.getLocalValidationErrors = function(attribute, path) {

            if (_.isNil(path)) {
                path = 'item["' + attribute.name + '"]';
            }

            var editorScope = self.$scope;

            var attributeLocalValidations = editorScope.localValidations[path];
            if (!_.isNil(attributeLocalValidations)) {
                return _.defaultTo(attributeLocalValidations.errors, []);
            } else {
                return [];
            }
        };

        self.hasLocalValidationErrors = function(attribute, path) {

            if (attribute.typeName != 'MultiDimensional') {
                return false;
            }

            return !_.isEmpty(self.getLocalValidationErrors(attribute, path));
        };

        /**
         * Key attributes are only relevant to Groups when the base
         * attribute is a MultiDimensional that is rendered as a Group.
         */
        self.isGroupKeyAttribute = function (attribute, parentAttribute) {
            if (parentAttribute.typeName === 'MultiDimensional') {
                return MultiDimensionalAttributeService.isKeyAttribute(attribute, parentAttribute);
            } else {
                return false;
            }
        };

    });