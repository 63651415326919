angular.module('llax')
    .controller('DataModelExchangeController', ['$rootScope', '$scope', 'UseDatamodelResource', 'growl', '$modal',
        'DataModelTemplateResource', 'FeatureToggleService', 'OrganizationService', 'ChannelService',
        function($rootScope, $scope, UseDatamodelResource, growl, $modal,
                 DataModelTemplateResource, FeatureToggleService, OrganizationService, ChannelService) {

            function downloadModelLink(modelName, downloadFormat) {
                return lax_rest_url('models/') + modelName + '/?downloadFormat=' + downloadFormat;
            }

            // FIXME: Get 'name', 'imageUrl' and 'version' for system defined data models from backend!
            $scope.defaultDataModels = [];
            if (FeatureToggleService.checkFeatureEnabled('legacyDataModels')) {
                $scope.defaultDataModels.push({
                    id: 'food-regulation-eu1169',
                    name: 'MODELEXCHANGE.FOOD_REGULATION_EU',
                    imageUrl: '/images/datamodels/data-model-food-regulation-eu1169.jpg',
                    ownerName: 'BYRD',
                    isSystemDataModel: true
                });
            }
            if (FeatureToggleService.checkFeatureEnabled('legacyDataModels')) {
                $scope.defaultDataModels.push({
                    id: 'healthcare',
                    name: 'MODELEXCHANGE.HEALTH_CARE',
                    imageUrl: '/images/datamodels/data-model-healthcare.jpg',
                    ownerName: 'BYRD',
                    isSystemDataModel: true
                });
            }
            $scope.defaultDataModels.push({
                id: 'office-supplies',
                name: 'MODELEXCHANGE.OFFICE_SUPPLIES',
                imageUrl: '/images/datamodels/data-model-office-supplies.png',
                ownerName: 'BYRD',
                isSystemDataModel: true,
                version: '1.0'
            });
            $scope.defaultDataModels.push({
                id: 'demomodel',
                name: 'MODELEXCHANGE.DEMOMODEL',
                imageUrl: '/images/datamodels/data-model-demomodel.png',
                ownerName: 'BYRD',
                isSystemDataModel: true,
                version: '1.5.70'
            });
            _.each($scope.defaultDataModels, function(datamodel) {
                datamodel.url = downloadModelLink(datamodel.id, 'zip');
            });

            function arrangeDataModels() {
                var activeDatamodel = _.find($scope.dataModels, function(dm) {
                    return (!dm.version || (dm.version == $scope.organization.dataModelVersion)) &&
                            (dm.name == $scope.organization.dataModelName || dm.id == $scope.organization.dataModelName) &&
                            (!dm.owner || dm.owner == $scope.organization.dataModelOwnerId);
                });

                if (!_.isEmpty(activeDatamodel)) {
                    activeDatamodel.active = true;
                    var idx = $scope.dataModels.indexOf(activeDatamodel);
                    $scope.dataModels.splice(idx,1);
                    $scope.dataModels = _.sortBy($scope.dataModels, [function(o) { return o.name.toLowerCase(); }]);
                    $scope.dataModels.unshift(activeDatamodel);
                } else if (!_.isUndefined($scope.organization.dataModelName)) {
                    var dummyInfo = {
                        name : $scope.organization.dataModelName,
                        version : $scope.organization.dataModelVersion || '1.0',
                        labels : $scope.organization.dataModelLabels || {},
                        ownerEmail : $scope.organization.email || '-',
                        ownerName : $scope.organization.name || '-',
                        active : true,
                        activateable : false
                    };
                    $scope.dataModels = _.sortBy($scope.dataModels, [function(o) { return o.name.toLowerCase(); }]);
                    $scope.dataModels.unshift(dummyInfo);
                }
                _.forEach($scope.dataModels, function(dm) {
                    dm.displayName = dm.name || "";
                    dm.dataModelDeployedAt = $scope.organization.dataModelDeployedAt;
                    dm.dataModelDeployedBy = $scope.organization.dataModelDeployedBy;
                    dm.htmlId = $scope.translate(dm.name).toLowerCase().replace(/\s+/g,'-').replace(/\//g,'');
                    if (!_.isEmpty(dm.labels)) {
                        var langKey = localStorage.getItem("languageKey");
                        dm.displayName = dm.labels[langKey] || dm.labels[''] || dm.name;
                    }
                    if (dm.version) {
                        dm.displayName = $scope.translate(dm.displayName) + ' ' + dm.version;
                    }
                });
            }

            function loadDataModels() {
                DataModelTemplateResource.query(function(response) {

                    OrganizationService.$organization.subscribe(function(organization) {
                        $scope.organization = angular.copy(organization) || {};
                        $scope.organization.dataModelName = $scope.organization.dataModelName || $scope.organization.dataModelClass;
                        $scope.dataModels = angular.copy($scope.defaultDataModels.concat(response));
                        arrangeDataModels();
                    });
                });
            }

            loadDataModels();

            $scope.manageDataModels = function() {
                var modal = $modal.open({
                    templateUrl: 'tpl/manage-datamodels.tpl.html',
                    controller: 'ManageDataModelsController',
                    windowClass: 'manage-datamodel-modal',
                    scope: $scope,
                });
                modal.result.then(function() {
                    DataModelTemplateResource.query(function(response) {
                        var systemDataModels = angular.copy($scope.defaultDataModels);
                        $scope.dataModels = systemDataModels.concat(response);
                        arrangeDataModels();
                    });
                });
            };

            $scope.$on('channelMessageReceived', function(event, eventData) {
                if (eventData.event === ChannelService.DATA_MODEL_ACTIVATEABLE_EVENT) {
                    growl.success("DATA_MODEL_TEMPLATE_ACTIVATED_SUCCESS", {
                            variables: { templatename: eventData.data.name },
                            ttl: 3000
                        });

                    loadDataModels();
                } else if (eventData.event === ChannelService.DATA_MODEL_TEMPLATE_INVALID_EVENT) {
                    growl.error("DATA_MODEL_TEMPLATE_ACTIVATED_FAILURE" , {
                        variables: { templatename: eventData.data.name, errorMessage: eventData.data.error}
                    });
                }
             });
        }
    ]);
