angular.module('llax')
    .controller('SearchQueryController',
        function($location, $log, $q, $rootScope, $routeParams, $scope, $translate, $timeout, $window, growl,
            DateTimeHelperService, ItemsSearchFilterResource, StatisticsResource, UserResource, WatchlistService, BulkSearchResource, Auth) {

            $scope.filterGroups = [];
            $scope.dropdownIsOpen = false;
            $scope.activeFilters = $scope.activeFilters || {};
            $scope.itemsSearchFilter = {};
            $scope.invalidFilterName = false;
            $scope.onFilterView = true;
            $scope.hasRightValidateItem = Auth.hasPermission(Auth.OBJECT_TYPE_UI, 'view.validateitems');

            var FILTER_TYPE_CATEGORY = 'CATEGORY';
            var FILTER_TYPE_DATA_MODEL_DEFINED = 'DATA_MODEL_DEFINED';
            var FILTER_TYPE_ITEM_SELECTION = 'ITEM_SELECTION';
            var FILTER_TYPE_PUBLICATION_TASK = 'PUBLICATION_TASK';
            var FILTER_TYPE_PUBLISHED_TO = 'PUBLISHED_TO';
            var FILTER_TYPE_USER_DEFINED = 'USER_DEFINED';
            var LOGICAL_OPERATORS_STRING = '\s+(AND|OR)\s+(?=(?:[^"]*"[^"]*")*[^"]*$)';
            var FILTER_TYPE_TASK = 'TASK';
            var FILTER_TYPE_VALIDATION_WARNINGS = 'VALIDATION_WARNINGS';
            var FILTER_TYPE_VALIDATION_ERRORS = 'VALIDATION_ERRORS';
            var FILTER_TYPE_REVIEW_ERRORS = 'REVIEW_ERRORS';
            var FILTER_TYPE_REVIEW_WARNINGS = 'REVIEW_WARNINGS';
            var FILTER_TYPE_COMPLIANT_ITEMS = 'COMPLIANT_ITEMS';
            var FILTER_TYPE_NON_COMPLIANT_ITEMS = 'NON_COMPLIANT_ITEMS';
            var FILTER_TYPE_BULK_SEARCH = 'BULK_SEARCH';
            $scope.publicationStatus = [
                { 'key' : "all", 'label': "ALL"},
                { 'key' : "APPROVED", 'label': "REVIEW.APPROVED"},
                { 'key' : "RECEIVED", 'label': "REVIEW.RECEIVED"},
                { 'key' : "REJECTED", 'label': "REVIEW.REJECTED"},
                { 'key' : "REVIEWED", 'label': "REVIEW.REVIEWED"},
                { 'key' : "MISSING", 'label': "REVIEW.MISSING"}
            ];

            var LocalStorage = $window.localStorage;

            $scope.labelClass = {
                'DATA_MODEL_DEFINED' : 'label-info',
                'USER_DEFINED' : 'label-info',
                'PUBLISHED_TO' : 'label-success',
                'TASK' : 'label-task',
                'VALIDATION_WARNINGS' : 'label-validation-warnings',
                'VALIDATION_ERRORS' : 'label-validation-errors',
                'REVIEW_WARNINGS' : 'label-validation-warnings',
                'REVIEW_ERRORS' : 'label-validation-errors',
                "COMPLIANT_ITEMS" : 'label-success',
                "NON_COMPLIANT_ITEMS" : 'label-validation-errors',
                "BULK_SEARCH" : 'label-bulk-search'
            };

            var MAX_URL_PARAM_LENGTH = 1024;

            $scope.currentLayout = $location.path();

            $scope.$on('$routeUpdate', function(scope, route) {
                if (_.isEmpty(route.params)) {
                    $scope.resetActiveFilters();
                    $scope.$emit('itemSearchQueryChanged', {keyword: ""});
                } else {
                    checkRouteParams(route.params);
                    $scope.$emit('itemSearchQueryChanged', $scope.query);
                }
                $scope.searchFilter.attributes = getSearchFilterAttributes($routeParams.category);
            });

            function encodeItemQuery(query) {
                return encodeURIComponent(query)
                    .replace(/'/g, "%27")
                    .replace(/"/g, "%22")
                    .replace(/\(/g, "%28")
                    .replace(/\)/g, "%29");
            }

            function decodeItemQuery(query) {
                return decodeURIComponent(query).replace(/:/g, ' = ');
            }

            function getSearchFilterAttributes(category) {
                var attributes = $rootScope.dataModel.filteredLayoutAttributes('filter');
                if (_.isEmpty(attributes)) {
                    attributes = $rootScope.dataModel.allAttributes();
                }
                if (!_.isNil(category) && category !== 'NO_CATEGORY') {
                    var categoryAttributeNames = $rootScope.dataModel.categoryAttributeNames(category);
                    attributes = _.filter(attributes, function(attribute) {
                        return _.includes(categoryAttributeNames, attribute.name);
                    });
                }
                $rootScope.prepareAttributes(attributes);
                return _.uniq(attributes);
            }

            $scope.searchFilter = {
                operators: [{
                    key: ':',
                    value: '='
                }, {
                    key: '==',
                    value: '=='
                }, {
                    key: '<',
                    value: '<'
                }, {
                    key: '<=',
                    value: '<='
                }, {
                    key: '>',
                    value: '>'
                }, {
                    key: '>=',
                    value: '>='
                }],
                attributes: getSearchFilterAttributes($routeParams.category)
            };

            function loadItemsSearchFilters() {
                $q.all([
                        ItemsSearchFilterResource.query().$promise,
                        UserResource.filters().$promise,
                        StatisticsResource.query({ name: "__validation_overview" }).$promise,
                        StatisticsResource.query({ name: "__validation_warnings" }).$promise
                    ])
                    .then(function(data) {
                        var userFilters = data[0];
                        var dataModelFilters = data[1];
                        var errors = data[2];
                        var warnings = data[3];

                        _.forEach(userFilters, function(filter) {
                            angular.extend(filter, {
                                label: filter.label || filter.name,
                                type: FILTER_TYPE_USER_DEFINED
                            });
                        });
                        _.forEach(dataModelFilters, function(filter) {
                            angular.extend(filter, {
                                label: filter.label || filter.name,
                                itemsQuery: {
                                    keyword: '(' + filter.query + ')'
                                },
                                type: FILTER_TYPE_DATA_MODEL_DEFINED
                            });
                        });

                        $scope.filters = _.concat(userFilters, dataModelFilters);

                        errors = translateAndSortByText(errors);
                        $scope.validationErrors = copyAndMarkFiltersByKey(errors, 'errorKey');
                        $scope.reviewErrors = copyAndMarkFiltersByKey(errors, 'reviewErrorKey');

                        warnings = translateAndSortByText(warnings);
                        $scope.validationWarnings = copyAndMarkFiltersByKey(warnings, 'warningKey');
                        $scope.reviewWarnings = copyAndMarkFiltersByKey(warnings, 'reviewWarningKey');

                        if (!_.isNil($scope.query.bulkSearchId)) {
                            BulkSearchResource.get({
                                bulkSearchId: $scope.query.bulkSearchId
                            }, function(response) {
                                if (response.status === 'READY') {
                                    growl.success('BULK_SEARCH.FINISHED');
                                    $rootScope.bulkSearchStillRunning = false;
                                } else if (response.status === 'RUNNING') {
                                    growl.success('BULK_SEARCH.STILL_RUNNING');
                                    $rootScope.bulkSearchStillRunning = true;
                                }
                            });
                        }

                    });
                    $scope.compliantFilters = [{
                        text : $rootScope.translateValidationLabel('ITEMS_SEARCH_FILTER.MENU.COMPLIANT'),
                        val : 'true',
                        type : FILTER_TYPE_COMPLIANT_ITEMS
                    },{
                        text : $rootScope.translateValidationLabel('ITEMS_SEARCH_FILTER.MENU.NON_COMPLIANT'),
                        val : 'false',
                        type : FILTER_TYPE_NON_COMPLIANT_ITEMS
                    }];
            }

            function translateAndSortByText(filters) {
                return _.sortBy(_.map(filters, function(filter) {
                    filter.text = $rootScope.translateValidationLabel(filter.key);
                    return filter;
                }), 'text');
            }

            function copyAndMarkFiltersByKey(filters, key) {
                var copy = angular.copy(filters);
                return _.map(copy, function(filter) {
                    filter.searchKey = key;
                    return filter;
                });
            }

            function isDoubleQuoted(value) {
                return _.startsWith(value, '"') && _.endsWith(value, '"');
            }

            function trimDoubleQuotes(str) {
                if (isDoubleQuoted(str)) {
                    return str.slice(1, -1);
                }
                return str;
            }

            function getCurrentFilter() {
                var queryString = '';
                _.forEach($scope.filterGroups, function(group, index) {
                    var hasAllAttributesEmpty = _.every(group.rules, function(rule) {
                        return _.isNil(rule.attribute) || (_.isEmpty(rule.keyword) && !(rule.keyword instanceof Date));
                    });

                    if (!hasAllAttributesEmpty) {
                        if (index > 0) {
                            queryString += ' ' + group.operator + ' ';
                        }
                        if (group.notOperator) {
                            queryString += 'NOT ';
                        }
                        queryString += '(';
                        _.forEach(group.rules, function(rule, index) {
                            if (rule.attribute && (!_.isEmpty(rule.keyword) || rule.keyword instanceof Date)) {
                                if (index > 0 && rule.connector && group.rules[index-1].keyword) {
                                    queryString += ' ' + rule.connector + ' ';
                                }

                                var val;
                                if (isDoubleQuoted(rule.keyword)) {
                                    val = rule.keyword;
                                } else {
                                    val = '"' + rule.keyword + '"';
                                }

                                if (!_.isNil(val)) {
                                    var ruleBaseClass = rule.attribute.baseClass;
                                    switch (ruleBaseClass) {
                                        case 'Array':
                                            val = _.map(val, function (v) { return v; }).join('');
                                        break;
                                        case 'DateTime':
                                            var timestamp = DateTimeHelperService.convertToTimestamp(val);
                                            val = _.toString(timestamp);
                                        break;
                                    }

                                }

                                if (val.indexOf(' OR ') > -1 ||val.indexOf(' AND ') > -1) {
                                    val = '(' + val + ')';
                                }
                                if (rule.notOperator) {
                                    queryString += 'NOT ';
                                }
                                queryString += (rule.attribute.name || rule.attribute) + rule.operator + val;
                            }
                        });
                        queryString += ')';
                    }
                });
                return {
                    keyword: queryString
                };
            }

            $scope.loadWatchlistItems = function() {
                var selectionId = $routeParams.selectionId || true;
                $location.search('selectionId', selectionId);
            };

            $scope.selectAllItems = function() {
                $scope.$emit('selectedItemsUpdated', 'ALL');
            };

            $scope.clearSelectedItems = function() {
                $scope.$emit('selectedItemsUpdated', 'NONE');
            };

            $scope.dropDownToggled = function(open, scope) {
                $scope.dropdownIsOpen = open;
                scope.showValidationErrors = false;
                scope.showValidationWarnings = false;
                _.forEach($scope.publicationDestinations, function(p) {
                    p.showStatus = false;
                });
            };

            $scope.addFilterRule = function(group) {
                if (group.rules && group.rules.length >= 1) {
                    group.operator = 'AND';
                }
                group.push({});
            };

            $scope.removeFilterRule = function(group, rule) {
                _.remove(group.rules, rule);
                if (group.rules.length === 0) {
                    _.remove($scope.filterGroups, group);
                }
                $scope.updateQuery(rule);
            };

            $scope.updateQuery = function(filter) {
                var query = getCurrentFilter();
                if ((filter.operator && !_.isUndefined(filter.keyword)) || (filter.operator && filter.rules) || !_.isEmpty(query.keyword)) {
                    $scope.query.keyword = decodeItemQuery(query.keyword);
                } else {
                    $scope.query.keyword = '';
                }
            };

            $scope.transformSearchQuery = function(text) {
                var option = {
                    translatedOption: text
                };
                return option;
            };

            $scope.addFilterGroup = function() {
                $scope.filterGroups.push({
                    rules: [{}]
                });
            };

            $scope.updateAttributeTemplate = function(rowScope, rule, gridOptions) {

                // Fix to reload a template with the same name
                var placeholder = {
                    template: "Placeholder"
                };

                if(_.isUndefined(rowScope.$parent.a)) {
                    rowScope.$parent.a = angular.copy(rule.attribute);
                    rowScope.$parent.a.readonly = false;
                } else {
                    rowScope.$parent.a = placeholder;
                    $timeout(function() {
                        rowScope.$parent.a = angular.copy(rule.attribute);
                        rowScope.$parent.a.readonly = false;
                    });
                }

                rule.keyword = undefined;
                rowScope.$parent.watch = {
                    keyword: ""
                };

                rowScope.$parent.$watch("watch.keyword", function(newKeyword) {
                    if (!_.isUndefined(newKeyword)) {
                        rule.keyword = newKeyword;
                        $scope.updateQuery(rule);
                    }
                }, true);

                $scope.updateQuery(rule);
            };

            // Preparing the attribute definition and watching the corresponding value
            // If attribute is not defined when loading the template
            $scope.prepareSearchFilterAttribute = function(rowScope,a,rule) {
                if(_.isNil(a)) {
                    var currentAttribute = _.find($scope.searchFilter.attributes, function(attr) {
                        return attr.name == rule.attribute;
                    });
                    rowScope.watch = {
                        keyword: ""
                    };
                    if (currentAttribute) {
                        rowScope.a = angular.copy(currentAttribute);
                        rowScope.a.readonly = false;
                        rule.attribute = rowScope.a;
                    }

                    if (!_.isNil(currentAttribute) && !_.isNil(rule.keyword)) {
                        switch (currentAttribute.baseClass) {
                            case 'Array':
                                rule.keyword = rule.keyword.match(/(".*?"|[^"\s]+)(?=\s*|\s*$)/g);
                                if (_.isArray(rule.keyword)) {
                                    rule.keyword = _.map(rule.keyword,function(ke) {
                                        ke = ke.replace(/"/g, '');
                                        ke = _.trim(ke);
                                        return ke;
                                    });
                                }
                            break;
                            case 'DateTime':
                                rule.keyword = DateTimeHelperService.getDateFromTimestamp(rule.keyword);
                            break;
                        }
                    }

                    if (isDoubleQuoted(rule.keyword)) {
                        rule.keyword = trimDoubleQuotes(rule.keyword);
                    }

                    rowScope.watch.keyword = rule.keyword;
                    rowScope.$watch("watch.keyword", function(newKeyword) {
                        if (!_.isUndefined(newKeyword)) {
                            rule.keyword = newKeyword;
                            $scope.updateQuery(rule);
                        }
                    }, true);
                }
            };

            $scope.isAllowedTemplate = function(templateName) {

                var allowedTemplates = [
                    "Enum",
                    "EnumSet",
                    "String",
                    "Boolean",
                    "Date",
                    "DateTime",
                    // With allowing a placeholder, the default Inputfield is not loaded. This prevents ugly flickering.
                    "Placeholder",
                    "SingleBoolean",
                    "OpenEnum",
                    "OpenEnumSet",
                ];

                if (_.includes(allowedTemplates, templateName)) {
                    return true;
                } else {
                    return false;
                }

            };

            $scope.onPaste = function(evt, scope) {

                var clipboardData = (evt.clipboardData || evt.originalEvent.clipboardData || window.clipboardData);
                var pastedData;
                try {
                    pastedData = clipboardData.getData('Text') || clipboardData.getData('text/plain');
                } catch (e) {
                    $log.error("Could not access clipboard", e);
                    return;
                }

                pastedData = _.trim(pastedData);
                if (_.isEmpty(pastedData)) {
                    return;
                }

                var queryText = evt.originalEvent.target.value;
                var textSelectionStart = evt.originalEvent.target.selectionStart;
                var textSelectionEnd = evt.originalEvent.target.selectionEnd;

                var pre = '';
                var post = '';

                $timeout(function() {

                    pre = queryText.substring(0, textSelectionStart);
                    post = queryText.substring(textSelectionEnd);

                    // Split data into words separated by 'newline' or 'return',
                    // Join by " OR "
                    var rows = _.words(pastedData, /[^\n\r]+/g);
                    var modifiedData = _.join(rows, ' OR ');
                    if (scope && _.isObject(scope.watch)) {
                        scope.watch.keyword = modifiedData;
                    } else {
                        $scope.query.keyword = pre + modifiedData + post;
                    }

                }, 0);

            };

            function toggleFilter(filter) {
                if (filter.type === FILTER_TYPE_USER_DEFINED) {
                    removeActiveFilter(FILTER_TYPE_DATA_MODEL_DEFINED);
                } else if (filter.type === FILTER_TYPE_DATA_MODEL_DEFINED) {
                    removeActiveFilter(FILTER_TYPE_USER_DEFINED);
                }
                setActiveFilter(filter);
            }

            $scope.setActiveFilter = function(type, itemsSearchFilter) {

                if (type && itemsSearchFilter) {
                    toggleFilter(itemsSearchFilter);
                } else {
                    $scope.resetActiveFilters();
                }

                if (itemsSearchFilter.itemsQuery && itemsSearchFilter.itemsQuery.keyword) {
                    $scope.query.keyword = decodeItemQuery(itemsSearchFilter.itemsQuery.keyword);
                }
            };

            function removeActiveFilter(type) {
                delete $scope.activeFilters[type];
            }

            function setActiveFilter(filter) {
                $scope.activeFilters[filter.type] = filter;
            }

            $scope.resetActiveFilters = function() {
                $scope.activeFilters = {};
                $scope.query.keyword = '';
            };

            $scope.createFilter = function() {
                var category = $scope.activeFilters[FILTER_TYPE_CATEGORY];
                $scope.clearSearchQuery(category);
                $scope.showEditFilter = true;
                $scope.addFilterGroup();
            };

            $scope.editFilter = function(itemsSearchFilter) {
                if (itemsSearchFilter.key) {
                    return;
                }
                $scope.showEditFilter = true;
                $scope.filterGroups = [];
                $scope.itemsSearchFilter = {
                    name: itemsSearchFilter.name
                };

                var regexForValues = new RegExp(LOGICAL_OPERATORS_STRING);
                var regexForOperators = new RegExp(LOGICAL_OPERATORS_STRING,'g');
                var operator = '';
                var groupOperator = '';
                var ruleOperator = '';
                var hasGroupNotOperator = false;
                var queryParts = $rootScope.splitStringByOuterParentheses(itemsSearchFilter.itemsQuery.keyword);

                _.forEach(queryParts, function(part, index) {
                    var queryPart = _.trim(part);
                    if (queryPart === 'AND NOT' || queryPart === 'OR NOT') {
                        queryPart = queryPart.replace('NOT','');
                        queryPart = _.trim(queryPart);
                        hasGroupNotOperator = true;
                    }
                    if (queryPart !== 'AND' && queryPart !== 'OR') {
                        var group = {
                            rules: []
                        };
                        if (groupOperator !== '') {
                            group.operator = groupOperator;
                            groupOperator = '';
                        }
                        if (hasGroupNotOperator) {
                            group.notOperator = true;
                            hasGroupNotOperator = false;
                        }
                        var components = queryPart.split(regexForValues).filter(function(e) { return e; });
                        var operators = queryPart.match(regexForOperators);
                        _.forEach(components, function(entity,idx) {
                            if (entity !== 'AND' && entity !== 'OR') {
                                var ruleArray = [];
                                _.forEach($scope.searchFilter.operators, function(op) {
                                    var splitted = entity.split(op.key);
                                    if (splitted.length === 2) {
                                        ruleArray = splitted;
                                        operator = op.key;
                                    }
                                });
                                var hasNotOperator = false;
                                if (ruleArray[0] && ruleArray[0].indexOf('NOT') > -1) {
                                    ruleArray[0] = ruleArray[0].replace('NOT','');
                                    ruleArray[0] =_.trim(ruleArray[0]);
                                    hasNotOperator = true;
                                }
                                var rule = {
                                    attribute: _.trim(ruleArray[0]),
                                    operator: operator,
                                    keyword: _.trim(ruleArray[1]),
                                    notOperator: hasNotOperator
                                };
                                if (/\(*\)/.test(rule.keyword)) {
                                    rule.keyword = rule.keyword.replace(/\(|\)/g,'');
                                }
                                if (operators && operators.length && idx>0) {
                                    rule.connector = _.trim(operators[idx-1] || '');
                                }
                                group.rules.push(rule);
                            } else {
                                ruleOperator = entity;
                            }
                        });
                        $scope.filterGroups.push(group);
                    } else {
                        groupOperator = queryPart;
                    }
                });
            };

            $scope.saveFilter = function(itemsSearchFilter) {
                $scope.invalidFilterName = false;
                if (!itemsSearchFilter.name) {
                    $scope.invalidFilterName = true;
                    growl.warning('ITEMS_SEARCH_FILTER.MISSING_FILTER_NAME');
                    return false;
                }

                angular.extend(itemsSearchFilter, {
                    itemsQuery: getCurrentFilter()
                });

                if ($scope.activeFilters.USER_DEFINED && $scope.activeFilters.USER_DEFINED.name === itemsSearchFilter.name) {
                    ItemsSearchFilterResource.delete({
                        identifier: itemsSearchFilter.name
                    }, function(response) {
                        ItemsSearchFilterResource.save(itemsSearchFilter, function(response) {
                            $scope.resetFilterEditor();
                            loadItemsSearchFilters();
                            angular.extend(itemsSearchFilter, {
                                label: itemsSearchFilter.name,
                                type: FILTER_TYPE_USER_DEFINED
                            });
                            $scope.setActiveFilter(FILTER_TYPE_USER_DEFINED, itemsSearchFilter);
                            growl.success('ITEMS_SEARCH_FILTER.SAVE_SUCCESS_MESSAGE');
                        }, function(errorResponse) {
                            growl.error('ITEMS_SEARCH_FILTER.SAVE_ERROR_MESSAGE');
                        });
                    });
                } else {
                    ItemsSearchFilterResource.save(itemsSearchFilter, function(response) {
                        $scope.resetFilterEditor();
                        loadItemsSearchFilters();
                        angular.extend(itemsSearchFilter, {
                            label: itemsSearchFilter.name,
                            type: FILTER_TYPE_USER_DEFINED
                        });
                        $scope.setActiveFilter(FILTER_TYPE_USER_DEFINED, itemsSearchFilter);
                        growl.success('ITEMS_SEARCH_FILTER.SAVE_SUCCESS_MESSAGE');
                    }, function(errorResponse) {
                        if (errorResponse.status == 409) {
                            growl.error('ITEMS_SEARCH_FILTER.SAVE_ERROR_CONFLICT_MESSAGE', {variables: {filterName: errorResponse.data.data[0]}});
                            angular.element(".filter-new-profile .form-control").focus();
                        } else {
                            growl.error('ITEMS_SEARCH_FILTER.SAVE_ERROR_MESSAGE');
                        }
                    });
                }
            };

            $scope.deleteFilter = function(itemsSearchFilter) {
                ItemsSearchFilterResource.delete({
                    identifier: itemsSearchFilter.name
                }, function(response) {
                    growl.success('ITEMS_SEARCH_FILTER.DELETE_SUCCESS_MESSAGE');
                    loadItemsSearchFilters();
                    removeActiveFilter(FILTER_TYPE_USER_DEFINED);
                }, function(errorResponse) {
                    growl.error('ITEMS_SEARCH_FILTER.DELETE_ERROR_MESSAGE');
                });
            };

            $scope.resetFilterEditor = function() {
                $scope.showEditFilter = false;
                $scope.filterGroups = [];
                $scope.itemsSearchFilter = {};
                $scope.invalidFilterName = false;
            };

            $scope.clearSearchQuery = function (category) {
                if (!_.isNil(category)) {
                    $location.url($location.path() + "?category=" + category.name);
                    $scope.resetFilterEditor();
                    $scope.resetActiveFilters();
                    setActiveFilter(category);
                }
            };

            $scope.removeFilter = function(filter) {
                if (filter.type === FILTER_TYPE_CATEGORY) {
                    $location.search('category', null);
                    delete $scope.query.category;
                } else if (filter.type === FILTER_TYPE_ITEM_SELECTION) {
                    $location.search('selectionId', null);
                    delete $scope.query.selectionId;
                } else if (filter.type === FILTER_TYPE_PUBLISHED_TO) {
                    $location.search('publicationDestination', null);
                    delete $scope.query.publicationDestination;
                    $location.search('reviewStatus', null);
                    delete $scope.query.reviewStatus;
                    $location.search('reviewer', null);
                    delete $scope.query.reviewer;
                } else if (filter.type === FILTER_TYPE_PUBLICATION_TASK) {
                    $location.search('publicationTaskId', null);
                    $location.search('withPublicationTaskChecksums', null);
                    delete $scope.query.publicationTaskId;
                    delete $scope.query.withPublicationTaskChecksums;
                } else if (filter.type === FILTER_TYPE_TASK) {
                    $location.search('taskId', null);
                    $location.search('tags', null);
                    delete $scope.query.taskId;
                    delete $scope.query.tags;
                } else if (filter.type === FILTER_TYPE_BULK_SEARCH) {
                    $location.search('bulkSearchId', null);
                    $location.search('tags', null);
                    delete $scope.query.bulkSearchId;
                    delete $scope.query.tags;
                } else if (!_.isEmpty(filter.searchKey)) {
                    $location.search(filter.searchKey, null);
                    delete $scope.query[filter.searchKey];
                } else {
                    $scope.query.keyword = '';
                    $rootScope.$broadcast('itemSearchQueryChanged', {keyword: ''});
                }
                removeActiveFilter(filter.type);
            };

            $scope.fetchItemsForPublicationDestination = function(destination, status) {
                if (destination) {
                    $scope.query = $scope.query || {};
                    var filter = {
                        name: destination.name,
                        itemsQuery: {},
                        type: FILTER_TYPE_PUBLISHED_TO,
                        status : status,
                        key : destination.name
                    };
                    var searchParams = {};
                    $scope.setActiveFilter(FILTER_TYPE_PUBLISHED_TO, filter);
                    if (status && status != 'all') {
                        delete $scope.query.publicationDestination;
                        searchParams.reviewer = destination.key;
                        searchParams.reviewStatus = status;
                        filter.name = destination.name + ' : ' + $translate.instant('REVIEW.'+ status);
                    } else {
                        searchParams = { 'publicationDestination' : destination.key };
                        delete $scope.query.reviewStatus;
                        delete $scope.query.reviewer;
                    }
                    navigateBySameKey(searchParams);

                } else {
                    removeActiveFilter(FILTER_TYPE_PUBLISHED_TO);
                }
            };

            function navigateBySameKey(key, val) {
                if ($location.$$search.category || !_.isEmpty($location.$$search.q)) {
                    var url = $location.$$url;
                    if (_.isObject(key)) {
                        _.forEach(key, function(k,v) {
                            url = url + "&" + v + "=" + k;
                        });
                        $location.url(url);
                    } else {
                        var urlKey = key + "=";
                        var index = url.indexOf(urlKey);
                        if (index >= 0) {
                            index += urlKey.length;
                            $location.url(url.substring(0, index) + val);
                        } else {
                            $location.url(url + "&" + key + "=" + val);
                        }
                    }

                } else {
                    $location.url($location.path());
                    if (_.isObject(key)) {
                        $location.search(key);
                    } else {
                        $location.search(key, val);
                    }
                }
            }

            $scope.filterByValidationFilter = function(val) {
                navigateBySameKey(val.searchKey,val.key);
            };

            $scope.fetchItemsByCompliant = function(val) {
                navigateBySameKey('compliant',val);
            };

            $rootScope.$on('buckSearchPopupOpend', function(scope) {
                if ($scope.query) {
                    delete $scope.query.keyword;
                }
            });

            function checkRouteParams(routeParams) {

                if (routeParams.q) {
                    $scope.query.keyword = routeParams.q.split("+").join(" ");
                } else if($scope.query.keyword) {
                    $location.search('q', $scope.query.keyword);
                }
                if (routeParams.category) {
                    delete $scope.query.selectionId;
                } else if (routeParams.selectionId) {
                    delete $scope.query.category;
                }

                if ($scope.query) {
                    _.forEach(Object.keys($scope.query), function(key) {
                        if (!routeParams[key] && key != 'keyword') {
                            $location.search(key, $scope.query[key]);
                        }
                    });
                }

                var filter;
                if (routeParams.category) {
                    var category = routeParams.category;
                    filter = {
                        name: category,
                        itemsQuery: {
                            category: category
                        },
                        type: FILTER_TYPE_CATEGORY
                    };
                    removeActiveFilter(FILTER_TYPE_ITEM_SELECTION);
                    $scope.setActiveFilter(FILTER_TYPE_CATEGORY, filter);
                    $scope.query.category = category;
                }

                if (routeParams.reviewStatus) {
                    $scope.query.reviewStatus = routeParams.reviewStatus;
                }

                if (routeParams.publicationDestination || routeParams.reviewer) {
                    if (routeParams.reviewer) {
                        $scope.query.reviewer = routeParams.reviewer;
                    } else {
                        $scope.query.publicationDestination = routeParams.publicationDestination;
                    }

                    $timeout(function () {
                        var filter = {
                            key: routeParams.publicationDestination || routeParams.reviewer,
                            itemsQuery: {},
                            type: FILTER_TYPE_PUBLISHED_TO
                        };
                        var key = isNaN(filter.key) ? filter.key : Number(filter.key);
                        var dest = _.find($scope.publicationDestinations, {
                            'key': key
                        });
                        filter.name = !_.isEmpty(dest) ? dest.name : filter.name;
                        if (routeParams.reviewStatus) {
                            filter.name += ' : ' + $translate.instant('REVIEW.'+ routeParams.reviewStatus);
                        }
                        var activeFilter = $scope.activeFilters[filter.type];
                        var activeFilterName = activeFilter ? activeFilter.name : "";
                        if (!_.isEqual(filter.name, activeFilterName)) {
                            $scope.setActiveFilter(FILTER_TYPE_PUBLISHED_TO, filter);
                        }
                    }, 0);
                } else {
                    delete $scope.activeFilters[FILTER_TYPE_PUBLISHED_TO];
                }

                if (routeParams.publicationTaskId) {
                    $scope.query.publicationTaskId = routeParams.publicationTaskId;
                    filter = {
                        name: routeParams.publicationTaskId,
                        itemsQuery: {},
                        type: FILTER_TYPE_PUBLICATION_TASK
                    };
                    $scope.setActiveFilter(FILTER_TYPE_PUBLICATION_TASK, filter);
                }

                if (routeParams.firstPrimaryKey && routeParams.secondPrimaryKey) {
                    var primaryKeys = [];
                    primaryKeys.push(routeParams.firstPrimaryKey);
                    primaryKeys.push(routeParams.secondPrimaryKey);
                    $scope.compareItems(primaryKeys);
                }

                if (routeParams.selectionId) {
                    $scope.query.selectionId = routeParams.selectionId;
                    filter = {
                        name: $translate.instant(FILTER_TYPE_ITEM_SELECTION),
                        itemsQuery: {
                            primaryKeys: WatchlistService.getWatchlistPrimaryKeys()
                        },
                        type: FILTER_TYPE_ITEM_SELECTION
                    };
                    removeActiveFilter(FILTER_TYPE_CATEGORY);
                    $scope.setActiveFilter(FILTER_TYPE_ITEM_SELECTION, filter);
                }

                if (routeParams.bulkSearchId) {
                    delete $scope.query.taskId;
                    delete routeParams.taskId;
                    delete $routeParams.taskId;

                    if ($scope.query == "") {
                        $scope.query = {};
                    }

                    removeActiveFilter(FILTER_TYPE_TASK);
                    $scope.query.bulkSearchId = routeParams.bulkSearchId;
                    if (routeParams.tags) {
                        $scope.query.tags = routeParams.tags;
                    }
                    var name = $translate.instant(FILTER_TYPE_BULK_SEARCH);

                    filter = {
                        name: name,
                        type: FILTER_TYPE_BULK_SEARCH
                    };

                    $scope.activeFilters[FILTER_TYPE_BULK_SEARCH] = filter;
                } else {
                    delete $scope.query.bulkSearchId;
                    delete $scope.query.tags;
                    delete $scope.activeFilters[FILTER_TYPE_BULK_SEARCH];
                }

                if (routeParams.taskId) {
                    if ($scope.task && $scope.task.$promise) {
                        $scope.task.$promise.then(function(res) {
                            setTaskFilter(res, routeParams);
                        });
                    } else {
                        $rootScope.$on('taskLoaded', function(scope, task) {
                            setTaskFilter(task, routeParams);
                        });
                    }
                }

                var key;
                if (routeParams.errorKey) {
                    key = 'errorKey';
                    $scope.query[key] = routeParams[key];
                    setValidationFilter({text : $rootScope.translateValidationLabel(routeParams.errorKey), searchKey:key});
                } else {
                    delete $scope.activeFilters[FILTER_TYPE_VALIDATION_ERRORS];
                }

                if (routeParams.warningKey) {
                    key = 'warningKey';
                    $scope.query[key] = routeParams[key];
                    setValidationFilter({text : $rootScope.translateValidationLabel(routeParams.warningKey), searchKey:key});
                } else {
                    delete $scope.activeFilters[FILTER_TYPE_VALIDATION_WARNINGS];
                }

                if (routeParams.reviewErrorKey) {
                    key = 'reviewErrorKey';
                    $scope.query[key] = shortenParamString(routeParams[key]);
                    setValidationFilter({text : $rootScope.translateValidationLabel(routeParams.reviewErrorKey), searchKey:key});
                } else {
                    delete $scope.activeFilters[FILTER_TYPE_REVIEW_ERRORS];
                }

                if (routeParams.reviewWarningKey) {
                    key = 'reviewWarningKey';
                    $scope.query[key] = shortenParamString(routeParams[key]);
                    setValidationFilter({text : $rootScope.translateValidationLabel(routeParams.reviewWarningKey), searchKey:key});
                } else {
                    delete $scope.activeFilters[FILTER_TYPE_REVIEW_WARNINGS];
                }

                if (routeParams.compliant) {
                    key = 'compliant';
                    $scope.query[key] = routeParams[key];
                    setValidationFilter({compliant : routeParams.compliant, searchKey:key});
                } else {
                    delete $scope.activeFilters[FILTER_TYPE_COMPLIANT_ITEMS];
                    delete $scope.activeFilters[FILTER_TYPE_NON_COMPLIANT_ITEMS];
                }
            }

            function setTaskFilter(task, routeParams) {
                $scope.query.taskId = routeParams.taskId;
                if (routeParams.tags) {
                    $scope.query.tags = routeParams.tags;
                }
                if (!_.isEmpty(task) && !_.isEmpty(task.title)) {
                    var filter = {
                        name: task.title,
                        type: FILTER_TYPE_TASK
                    };
                    $scope.activeFilters[FILTER_TYPE_TASK] = filter;
                }
            }

            function setValidationFilter(val) {
                if (!_.isEmpty(val)) {
                    var type = '';
                    if (val.searchKey == 'errorKey') {
                        type = FILTER_TYPE_VALIDATION_ERRORS;
                    } else if (val.searchKey == 'warningKey') {
                        type = FILTER_TYPE_VALIDATION_WARNINGS;
                    }  else if (val.searchKey == 'reviewErrorKey') {
                        type = FILTER_TYPE_REVIEW_ERRORS;
                    }  else if (val.searchKey == 'reviewWarningKey') {
                        type = FILTER_TYPE_REVIEW_WARNINGS;
                    } else if (val.searchKey == 'compliant') {
                        var comp = _.find($scope.compliantFilters, {val : val.compliant});
                        if (comp) {
                            type = comp.type;
                            val.text = comp.text;
                        }
                        if (val.compliant == 'true') {
                            delete $scope.activeFilters[FILTER_TYPE_NON_COMPLIANT_ITEMS];
                        } else if (val.compliant == 'false') {
                            delete $scope.activeFilters[FILTER_TYPE_COMPLIANT_ITEMS];
                        }
                    }
                    var filter = {
                        name: val.text,
                        itemsQuery: {},
                        type: type,
                        searchKey : val.searchKey
                    };
                    $scope.activeFilters[type] = filter;
                }
            }

            function shortenParamString(param) {
                // This regex returns the first MAX_URL_PARAM_LENGTH(any) characters plus any subsequent non-space characters.
                return param.replace(new RegExp("^(.{" + MAX_URL_PARAM_LENGTH + "}[^\s]*).*"), "$1");
            }

            (function init() {
                loadItemsSearchFilters();
                checkRouteParams($routeParams);
            })();
        }
    );
