angular.module('llax')
    .controller('EditorController',
        function($controller, $dialogs, $document, $location, $log, $modal, $modalInstance,
            $parse, $q, $rootScope, $routeParams, $scope, $timeout, $translate, $window, growl, params,
            AssetFoldersService, Auth, AdditionalCategoryAttributeService, ChannelService, CommunicationChannelService,
            DefaultItemResource, GroupAttributeService, InputTemplatesService, ItemResource, ItemChangesQueueManager,
            OrganizationService, ReferenceAttributesService, SessionDataService, SupplierReviewResource, TaskResource, uiGridConstants, UrlRetrievalService,
            UsersService, ValidateItemService) {

            var LOAD_WITH_VALIDATIONS = true;
            var TEMP_ITEM = "tmpItem";
            var TEMP_TASK = "tmpTask";
            var CUSTOM_ITEM_REVIEW_SERVICE = "CustomItemReviewSettings";
            var CUSTOM_ITEM_SERVICE = "CustomItemEditorSettings";

            var VALIDATION_STATUS_IS_DIRTY = "isDirty";
            var VALIDATION_STATUS_IS_COMPLIANT = "isCompliant";
            var VALIDATION_STATUS_IS_NOT_COMPLIANT = "isNotCompliant";
            var VALIDATION_STATUS_IS_RUNNING = "isRunning";

            var GRID_STATE_KEY_PREFIX = "gridState:";

            var LocalStorage = $window.localStorage;

            $scope.onShowSettings = false;
            $scope.focusOn = true;
            $scope.enableAudit = true;
            $scope.showCategorySection = true;
            $rootScope.additionalModalOpen = false;

            $scope.item = {};
            $scope.originalItem = {};
            $scope.attributeStates = {};
            $scope.validations = {};

            $scope.validationStatus = null;
            $scope.validationErrorsCount = 0;
            $scope.validationWarningsCount = 0;

            $scope.allAttributes = [];
            $scope.searchableSubAttributes = [];
            $scope.isItemEditable = null;
            $scope.groupIndexes =[];
            $scope.connectedUserIds = [];
            $scope.connectedAccounts = [];

            $scope.itemHierarchies = [];
            $scope.itemHierarchyStatus = [];

            // A map of local (client-side) validations where the attribute path in the item
            // is the key.
            $scope.localValidations = {};

            $scope.vm = {
                desiredAuditStatus: null
            };

            $scope.displayEditor = false;
            function displayEditor() {
                $timeout(function() {
                    $scope.displayEditor = true;
                }, 1);
            }

            var taskFromSession = JSON.parse(LocalStorage.getItem(TEMP_TASK));
            LocalStorage.removeItem(TEMP_TASK);

            $scope.task = _.isNil(params.task)? taskFromSession : params.task;

            $scope.currentLayout = 'edit';

            OrganizationService.$organization.subscribe(function(organization) {
                $scope.organization = angular.copy(organization);
            });

            $scope.sectionToFocus = $routeParams.section;
            $scope.attributeToFocus = $routeParams.attribute;

            // Unset routing params when opening editor, otherwise they keep 'cached' when opening the editor again!
            $routeParams.section = undefined;
            $routeParams.attribute = undefined;

            $scope.isCopiedItem = ($location.path().startsWith('/copy/'));
            $scope.isNewItem = ($location.path() == '/edit' || $location.path().startsWith('/edit/new') || $scope.isCopiedItem);
            $scope.allCategories = [];

            $scope.isInitialValidationFinished = ($scope.isNewItem == true) ? true : false;

            // Extend editor dialog scope with passed params (showDetails, showHistory...etc).
            _.extend($scope, params.editorState);

            GroupAttributeService.initializeScope($scope);

            $controller('EditorViewController', {
                $scope: $scope
            });

            $scope.isRowEditable = function(row) {
                if (!_.isNil($scope.isItemEditable)) {
                    row.editable = $scope.isItemEditable;
                }
                return row.editable;
            };

            var closeOtherPanels = SessionDataService.get("closeOtherPanels");
            if (closeOtherPanels) {
                $scope.closeOtherPanels = closeOtherPanels == "true" ? true : false;
            } else {
                SessionDataService.put("closeOtherPanels", true);
                $scope.closeOtherPanels = true;
            }

            $scope.toggleCloseOtherPanels = function(closeOtherPanels) {
                SessionDataService.put("closeOtherPanels", closeOtherPanels);
                $scope.closeOtherPanels = closeOtherPanels;
            };

            var disableDeleteConfirmation = LocalStorage.getItem("disableDeleteConfirmation-" + $rootScope.userId);
            if (disableDeleteConfirmation) {
                $scope.disableDeleteConfirmation = disableDeleteConfirmation == "true" ? true : false;
            } else {
                LocalStorage.setItem("disableDeleteConfirmation-" + $rootScope.userId, false);
                $scope.disableDeleteConfirmation = false;
            }

            $scope.toggleDisableDeleteConfirmation = function(disableDeleteConfirmation) {
                LocalStorage.setItem("disableDeleteConfirmation-" + $rootScope.userId, disableDeleteConfirmation);
                $scope.disableDeleteConfirmation = disableDeleteConfirmation;
            };

            $scope.temporaryModelForTypeahead = {};
            $scope.temporaryWarningsForTypeahead = {};
            $scope.addToReferenceList = function(item, a, label, model) {
                var modelEval = ReferenceAttributesService.getModelEval(model, a);
                var reference = modelEval($scope)|| [];

                reference.push(item);

                //add found element to $scope.item
                if (reference.length < 10) {
                    var parsedModelString = ReferenceAttributesService.parseModelString(model, a.name);
                    $scope.gridOptionsMap[parsedModelString].minRowsToShow++;
                }

                //clear input field, when item is added
                delete $scope.temporaryModelForTypeahead[a.name];

            };

            $scope.isShowUnderGroup = function(attribute, validation) {
                if (attribute.typeName === 'Group') {
                    return validation.path.length === 1;
                } else {
                    return true;
                }
            };

            $scope.addSingleReference = function (referencedItem, a, label, model) {

                ReferenceAttributesService.loadAndFormatItemAsync(referencedItem.primaryKey__, null, a)
                    .then(function(result) {
                        $timeout(function() {
                            ReferenceAttributesService.checkModelAndEval(model, a, referencedItem, $scope);
                            // "Hack" to bind a descriptive String including all filter elements to the item
                            $scope.temporaryModelForTypeahead[a.name] = result;
                        }, 0);
                    });
            };

            $scope.$on('clearAttributeValue', function (event, attributeName) {
                // For single reference attribute, remove typeahead when removing selection
                if ($scope.temporaryModelForTypeahead[attributeName]) {
                    delete $scope.temporaryModelForTypeahead[attributeName];
                }
            });

            $scope.startCalloutDelay = function(name, description) {

                $scope.calloutWaitingPromise = $timeout(function() {
                    // Ensure that we are only targeting visible attributes,
                    // in case of the same attribute being reused in different sections.
                    var htmlElementSelector = '.panel-collapse.collapse.in #' + name;
                    var target = $(htmlElementSelector)[0];
                    $scope.calloutWithId(target, name, description);
                }, 250);

            };

            $scope.stopCalloutDelay = function() {
                $timeout.cancel($scope.calloutWaitingPromise);
            };

            $scope.loadSingleItemForReference = function (attribute, item, name, model) {
                var modelEval = ReferenceAttributesService.getModelEval(model, attribute);
                var referencedItemPrimaryKey = modelEval($scope);

                if (_.isEmpty(referencedItemPrimaryKey)) {
                    return;
                }

                ReferenceAttributesService.loadAndFormatItemAsync(referencedItemPrimaryKey, $scope.item, attribute)
                    .then(function(result) {
                        $timeout(function(){
                            // "Hack" to bind the formatted reference item
                            $scope.temporaryModelForTypeahead[name] = result;
                        }, 0);
                    });
            };

            $scope.loadItemsForReference = function(attribute, item, name, model) {
                model = ReferenceAttributesService.parseModelString(model, name);
                var isReadonly = $scope.isAttributeReadonly(attribute);
                var modelEval = ReferenceAttributesService.getModelEval(model, attribute);
                var referencedItems = modelEval($scope);

                if (_.isEmpty(referencedItems)) {
                    return;
                }
                $rootScope.referencedItems[model] = angular.copy(referencedItems);
                ReferenceAttributesService.extendReferencedItemsAsync(attribute, referencedItems)
                        .then(function(result) {
                            // We can only filter the referenced items when in read-only state so
                            // we don't trigger a data change in the editor after the filtration.
                            if (isReadonly) {
                                modelEval.assign($scope, $rootScope.filterCollection(result, item, $rootScope.dataModel.attribute(name)));
                            } else {
                                modelEval.assign($scope, result);
                            }

                            // Trigger a rerender of the multi reference attribute, for example
                            // if the item was not found, we need to show an error
                            if (!_.isNil($scope.gridApiMap[model])) {
                                $timeout(function() {
                                    $scope.gridApiMap[model].core.notifyDataChange(uiGridConstants.dataChange.ALL);
                                }, 0);
                            }
                        });
            };

            $scope.findItems = function(keywordQuery, attribute) {
                return ReferenceAttributesService.loadAndFormatItemsToBeReferencedAsync(keywordQuery, $scope.item, attribute);
            };

            function getDataModelAttributes() {

                return $rootScope.dataModel.filteredLayoutAttributes($scope.currentLayout, $scope.item).map(function(attribute) {
                    return [attribute.label, 'item.' + attribute.name];
                });
            }

            // Overwrite Default Implementation of placeholder plugin
            CKEDITOR.on('dialogDefinition', function(ev) {
                // Take the dialog name and its definition from the event data.
                var dialogName = ev.data.name;
                var dialogDefinition = ev.data.definition;

                // Check if the definition is from the dialog we're interested on (the "Link" dialog).
                if (dialogName == 'placeholder') {
                    // Get a reference to the "Link Info" tab.
                    var infoTab = dialogDefinition.getContents("info");

                    infoTab.add({
                        type: 'select',
                        id: 'datamodelAttribute',
                        label: 'Attribute',
                        style: 'width: 300px;',
                        items: getDataModelAttributes(),
                        onShow: function() {
                            var values = this.getDialog().getContentElement('info', 'datamodelAttribute'); // 'general' is the id of the dialog panel.
                            removeAllOptions(values);

                            var opts = getDataModelAttributes();

                            for (var i = 0; i < opts.length; i++) {
                                addOption(values, opts[i][0], opts[i][1], this.getDialog().getParentEditor().document);
                            }
                        },

                        setup: function(widget) {
                            this.setValue(widget.data.name);
                        },
                        commit: function(widget) {
                            widget.setData('name', this.getValue());
                        }
                    });
                    infoTab.remove('name');
                }
            });

            $scope.editorOptions = {
                'basic': {
                    removePlugins: 'elementspath',
                    toolbar: [{
                        name: 'basicstyles',
                        items: ['Bold', 'Italic']
                    }, {
                        name: 'paragraph',
                        items: ['BulletedList', 'NumberedList']
                    }, {
                        name: 'links',
                        items: ['Link', 'Unlink']
                    }, {
                        name: 'insert',
                        items: ['CreatePlaceholder']
                    }],
                },
                'html': {
                    removePlugins: 'elementspath',
                    allowedContent: true,
                    toolbar: [{
                        name: 'styles',
                        items: ['Format']
                    }, {
                        name: 'basicstyles',
                        items: ['Bold', 'Italic']
                    }, {
                        name: 'paragraph',
                        items: ['NumberedList', 'BulletedList']
                    }, {
                        name: 'links',
                        items: ['Link', 'Unlink']
                    }, {
                        name: 'insert',
                        items: ['CreatePlaceholder', 'Table']
                    }, {
                        name: 'clipboard',
                        items: ['Paste', 'PasteText', 'PasteFromWord', '-', 'Undo', 'Redo']
                    }, {
                        name: 'document',
                        items: ['Source']
                    }, {
                        name: 'tools',
                        items: ['Maximize']
                    }]
                }
            };

            $scope.showPreview = {};

            $scope.resolveItemPlaceHolders = function(text) {
                if (!text) return;

                function replaceMatches(match) {

                    var property = match.substr(2, match.length - 4);

                    property = property.replace(/&#39;/g, "'"); // recreate quotes
                    property = property.replace(/&nbsp;/g, ""); // replace spaces
                    property = property.replace(/item/g, '$scope.item');

                    var value = $scope.$eval(property);

                    if (!value) {
                        value = match;
                    }
                    return value;
                }

                return text.replace(/\[\[.+?\]\]/g, replaceMatches);

            };

            var remoteEditedAttributes = [];

            $scope.checkAttributeEditedRemote = function(attribute) {
                if (remoteEditedAttributes.contains(attribute)) {
                    return "light-yellow";
                }
            };

            var messageTimeout;

            function removeAfterTimeout(field) {
                if (messageTimeout !== undefined) {
                    $timeout.cancel(messageTimeout);
                }

                messageTimeout = $timeout(function() {
                    $rootScope[field] = undefined;
                    messageTimeout = undefined;
                }, 5000);
            }

            function storeItemChanges() {
                var storeItemQueue = queueManager.getQueue('storeItemQueue');
                var queuedItemChanges = angular.copy(storeItemQueue.getQueuedItemChanges());
                queuedItemChanges.primaryKey__ = $scope.item.primaryKey__;
                $log.debug("queuedItemChanges:", queuedItemChanges);
                LocalStorage.setItem(TEMP_ITEM, JSON.stringify(queuedItemChanges));
            }

            $scope.$on('channelMessageReceived', function(event, eventData) {

                var type = eventData.type;

                var item = eventData.data.item;
                var action = eventData.data.action;
                var userId = eventData.data.userId;
                var userName = $rootScope.getUserName(userId);

                if (eventData.event === ChannelService.DATA_MODEL_CHANGED_EVENT) {
                    storeItemChanges();
                } else if (eventData.event === ChannelService.ITEM_CHANGED_EVENT) {

                    if (!$scope.saving && !$scope.closing) {

                        var typeOfChange = eventData.data.typeOfChange;
                        var primaryKeys = eventData.data.primaryKeys;

                        if (type === ChannelService.SUBSCRIBE_TYPE &&
                            typeOfChange === 'updated' &&
                            _.includes(primaryKeys, $scope.item.primaryKey__)) {

                            var itemModified = $rootScope.hasItemModified();
                            var message = $translate.instant(itemModified ? 'ITEM.UPDATED.UNSAVED_CHANGES' : $scope.isItemEditable ? 'ITEM.UPDATED.NO_CHANGES' : 'ITEM.UPDATED.NO_CHANGES_ANONYMOUS', {
                                user: userName
                            });

                            var infoDialog = $dialogs.notify('ITEM.UPDATED.HEADER', message);
                            infoDialog.result.then(function() {
                                storeItemChanges();
                                LocalStorage.setItem(TEMP_TASK, JSON.stringify($scope.task));
                                $window.location.reload();
                            });

                        }

                    }

                } else if (eventData.event === ChannelService.ITEM_EDITED_EVENT && eventData.identifier == $scope.item.primaryKey__) {

                    if (type === ChannelService.SUBSCRIBE_TYPE) {

                        if (item && userId && userId != $rootScope.user.userId) {

                            // Stop 'transmit' queue and restart again to prevent sending out the changes
                            stopTransmitItemChangesQueue();

                            // In order to correctly filter collection attributes, we first have to create
                            // an "unfiltered" item which can be used in the filter function!

                            var unFilteredItem = angular.copy($scope.item);
                            angular.extend(unFilteredItem, item);

                            // Update current item and filter collection type attributes, if necessary
                            angular.forEach(item, function(value, attributeName) {

                                if (!_.isArray(value)) {
                                    $scope.item[attributeName] = value;
                                    return;
                                }

                                var attribute = $rootScope.dataModel.attribute(attributeName);
                                if (_.isNil(attribute)) {
                                    $log.warn("edited item attribute '" + attributeName + "' was not found in data model");
                                    $scope.item[attributeName] = value;
                                    return;
                                }

                                if (attribute.typeName === 'MultiReference') {
                                    // Filtering will be done in 'loadItemsForReference'
                                    $scope.item[attributeName] = value;
                                    $scope.loadItemsForReference(attribute, $scope.item, attribute.name);
                                    return;
                                }

                                // Filter collection, if necessary
                                if ($scope.isAttributeReadonly(attribute)) {
                                    value = $rootScope.filterCollection(value, unFilteredItem, attribute);
                                }

                                $scope.item[attributeName] = value;

                            });

                            startTransmitItemChangesQueue();

                            angular.forEach(item, function(value, field) {
                                remoteEditedAttributes.addToSet(field);
                                var attribute = $rootScope.dataModel.attribute(field);

                                // Need to use 'sceParameters' sanitizing to keep the "<" characters of system users!
                                $rootScope.currentItemMessage = $translate.instant($scope.isItemEditable ? 'USER_UPDATED_FIELD' : 'USER_UPDATED_FIELD_ANONYMOUS', {
                                    user: userName,
                                    field: attribute.translatedLabel || $rootScope.translateAttribute(attribute)
                                }, null, null, "sceParameters");

                                removeAfterTimeout('currentItemMessage');
                            });

                        }

                    } else if (type === ChannelService.LISTEN_TYPE) {

                        if (userId && userId !== $rootScope.user.id) {

                            if (action === ChannelService.REGISTERED_ACTION) {

                                // Need to use 'sceParameters' sanitizing to keep the "<" characters of system users!
                                $rootScope.currentItemMessage = $translate.instant('USER_CONNECTED_TO_ITEM', {
                                    user: userName
                                }, null, null, "sceParameters");

                                removeAfterTimeout('currentItemMessage');
                                $scope.connectedUserIds.addToSet(userId);

                            } else if (action === ChannelService.UNREGISTERED_ACTION) {

                                // Need to use 'sceParameters' sanitizing to keep the "<" characters of system users!
                                $rootScope.currentItemMessage = $translate.instant('USER_DISCONNECTED_FROM_ITEM', {
                                    user: userName
                                }, null, null, "sceParameters");

                                removeAfterTimeout('currentItemMessage');
                                $scope.connectedUserIds.removeFromSet(userId);

                            }

                        }

                    }

                } else if (eventData.event === ChannelService.VALIDATION_FINISHED_EVENT && $scope.validationStatus == VALIDATION_STATUS_IS_DIRTY) {

                    // Check if our item is included in validation finished event
                    var validationResults = eventData.data.validationResults;
                    if (_.has(validationResults, $scope.item.primaryKey__)) {
                        reloadValidationResult();
                    }

                }

            });

            function reloadValidationResult() {

                var message = $translate.instant('ITEM_EDITOR.RELOAD_VALIDATION_RESULT.MESSAGE');
                var infoDialog = $dialogs.notify('ITEM_EDITOR.RELOAD_VALIDATION_RESULT.HEADER', message);
                infoDialog.result.then(function() {

                    // Reload validations and continue processing validation queue
                    $scope.displayEditor = false;
                    ItemResource.getValidationResult({ 'primaryKey': $scope.item.primaryKey__ }, function(itemResult) {

                        $scope.validations = itemResult.validations;
                        $scope.attributeStates = itemResult.attributeStates;
                        _.forEach(itemResult.calculations, function (calculatedAttributeValue, attributeName) {
                            $scope.item[attributeName] = calculatedAttributeValue;
                        });
                        setValidationStatus(null);

                        $scope.categorySelected().then(function() {

                            // Continue validation processor
                            queueManager.getQueue('validateItemQueue').continueProcessor();

                        }).finally(displayEditor);

                    }, function() {
                        displayEditor();
                    });

                });

            }

            function setConnectedAccounts() {
                if (_.isEmpty($scope.connectedUserIds)) {
                    $scope.connectedAccounts = [];
                } else {
                    $scope.connectedAccounts = _.map($scope.connectedUserIds, function(userId) {
                        var user = UsersService.getUser(userId);
                        return {
                            userId: user ? user.userId : userId,
                            name: user ? user.displayName : userId,
                            imageUrl: user ? user.imageUrl : null
                        };
                    });
                }
            }

            // Didn't know to make this work in 'karma'
            if ($scope.$watchCollection) {
                $scope.$watchCollection("connectedUserIds", setConnectedAccounts);
            }
            $scope.$on("usersLoaded", setConnectedAccounts);

            $scope.hasAttributeState = function(attribute, stateName) {
                if (_.isEmpty($scope.attributeStates)) {
                    return false;
                }
                var states = $scope.attributeStates[(_.isObject(attribute) ? attribute.name : attribute)];
                return !_.isEmpty(states) && _.includes(states, stateName);
            };

            $scope.isAttributeReadonly = function(attribute) {

                // Item is not editable
                if (!$scope.isItemEditable) {
                    return true;
                }

                // Attribute was set to 'readonly'
                if (_.isObject(attribute) && attribute.readonly) {
                    return true;
                }

                // Attribute was added to list of readonly attributes (legacy function, rather use 'states' by now!)
                if (!_.isEmpty($scope.item.readonlyAttributes__) &&
                    _.includes($scope.item.readonlyAttributes__, (_.isObject(attribute) ? attribute.name : attribute))) {
                    return true;
                }

                // Attribute state 'readonly' is set
                return $scope.hasAttributeState(attribute, 'readonly');
            };

            $scope.isAttributeHidden = function isAttributeHidden(attribute) {
                if (!$scope.isAttributeVisible(attribute)) {
                    return true;
                }

                // Attribute was set to 'hidden', via LayoutFilters for example.
                if (_.isObject(attribute) && attribute.hidden) {
                    return true;
                }

                if ($scope.hasAttributeState(attribute, 'hidden')) {
                    return true;
                }
                if (attribute.subAttributes && !_.isEmpty(attribute.subAttributes)) {
                    return _.every(attribute.subAttributes, function (subAttribute) {
                        return isAttributeHidden(subAttribute);
                    });
                }
                return false;
            };

            $scope.isAllAttributesHidden = function(attributes) {
                if (_.isEmpty(attributes)) {
                    return true;
                }
                var allHidden = _.every(attributes, function(attribute) {
                    return $scope.isAttributeHidden(attribute);
                });
                return allHidden;
            };

            $scope.initAttributesForSearch = function(forceRecalc) {
                if (forceRecalc) {
                    $scope.allAttributes = [];
                    $scope.searchableSubAttributes = [];
                }
                if (_.isEmpty($scope.allAttributes.length)) {
                    var memberAttributes = [];
                    // First add the root attributes
                    _.forEach($scope.sections, function(section) {
                        _.forEach(section.attributes, function(attribute) {
                            if (!_.some($scope.allAttributes, {name: attribute.name})) {
                                $scope.allAttributes.push(attribute);
                            }
                            _.forEach(attribute.memberAttributes, function (subAttribute) {
                                memberAttributes.push(subAttribute);
                            });
                        });
                    });
                    // Then add the sub-attributes
                    _.forEach(memberAttributes, function (subAttribute) {
                        if (!_.some($scope.allAttributes, {name: subAttribute.name}) &&
                            !_.some($scope.searchableSubAttributes, {name: subAttribute.name})) {
                            $scope.searchableSubAttributes.push(subAttribute);
                        }
                    });
                }
            };

            $scope.getAttributeSearchList = function (){
               return _.concat($scope.allAttributes, $scope.searchableSubAttributes);
            };

            $scope.updateHiddenForSearchAttributes = function() {
                if (_.isEmpty($scope.allAttributes)) {
                    return;
                }
                _.forEach($scope.allAttributes, function(attribute) {
                    attribute.hidden = $scope.isAttributeHidden(attribute);
                });
                _.forEach($scope.searchableSubAttributes, function(subAttribute) {
                    subAttribute.hidden = $scope.isAttributeHidden(subAttribute);
                });
            };

            $scope.queryAdditionalCategories = function(query, attribute, leaf, limit) {
                var dataModel = _.get(attribute.params, 'additionalModule');
                var extension = _.get(attribute.params, 'extension');
                leaf = leaf || null;
                var result = $rootScope.queryCategories(query, dataModel, extension, leaf, limit);
                return result;
            };

            $scope.updateAdditionalCategoryAttributes = function(item, attributes) {
                return loadAdditionalSectionAttributes($scope.currentLayout, item);
            };

            function loadAdditionalSectionAttributes(layout, item) {
                var defer = $q.defer();
                AdditionalCategoryAttributeService.loadAdditionalSectionAttributes(layout, item, function(additionalSectionAttributes) {
                    setSections(layout, item, additionalSectionAttributes);
                    defer.resolve();
                });
                return defer.promise;
            }

            function setSections(layout, item, additionalSectionAttributes) {

                UrlRetrievalService.clear();

                var sections = [];
                sections = $rootScope.dataModel.filteredSections(layout, item, additionalSectionAttributes);
                var rowLength = 0;
                angular.forEach(sections, function(section) {

                    section.rows = [];
                    var row = [];
                    angular.forEach(section.attributes, function(attribute, index) {

                        // add member-attributes to allowing searching them
                        attribute.memberAttributes = [];
                        if (_.includes(['Collection', 'MultiDimensional', 'Group'], attribute.typeName)) {
                            attribute.memberAttributes = $rootScope.dataModel.getMemberAttributes(attribute);
                        }

                        $rootScope.setInputRenderer(attribute);
                        $rootScope.prepareAttribute(attribute, true);

                        if (item.category__) {
                            // Pull 'readonly' and 'hidden' params from 'additionalParams', if any.
                            $rootScope.setSectionAttributeParam($scope.currentLayout, section.name, attribute, 'hidden', null, $scope.item);
                            $rootScope.setSectionAttributeParam($scope.currentLayout, section.name, attribute, 'readonly', null, $scope.item);
                        }
                        $rootScope.setSectionAttributeParam($scope.currentLayout, section.name, attribute, 'labelWidth', 4, $scope.item);
                        $rootScope.setSectionAttributeParam($scope.currentLayout, section.name, attribute, 'inputWidth', 8, $scope.item);
                        $rootScope.setSectionAttributeParam($scope.currentLayout, section.name, attribute, 'iconSize', '1em', $scope.item);
                        $rootScope.setSectionAttributeParam($scope.currentLayout, section.name, attribute, 'valuesFormat', ['label', 'key'], $scope.item);

                        attribute.isComplexType = (attribute.typeName == 'Collection' || attribute.typeName ==
                            'MultiDimensional' || attribute.typeName == 'MultiReference');

                        var needsFullRowRendering = attribute.isComplexType && attribute.params.rendererParams.type !== 'Group';
                        if (needsFullRowRendering) {
                            attribute.inputWidth += attribute.labelWidth;
                            attribute.labelWidth = 0;
                        }

                        attribute.labelClass = (attribute.labelWidth !== 0) ? 'col-sm-' + attribute.labelWidth : "";
                        attribute.inputClass = 'col-sm-' + attribute.inputWidth;

                        $rootScope.filterAttributeParams(attribute, item, $rootScope.user, $scope.organization);

                        rowLength += (attribute.labelWidth + attribute.inputWidth);
                        row.push(attribute);

                        if (rowLength >= 12) {
                            section.rows.push(row);
                            rowLength = 0;
                            row = [];
                        }
                    });
                });

                if ($scope.sections) {

                    var scopeIndex = 0;
                    var isOpen = false;

                    // Update the sections array directly to improve performance
                    for (var index = 0; index < sections.length; index++) {

                        var currentSection = sections[index];
                        var isEmpty = _.isEmpty(currentSection.attributes);

                        var scopeSection = $scope.sections[scopeIndex];
                        var isEqual = scopeSection && currentSection.name == scopeSection.name;

                        if (isEqual && !isEmpty) {
                            isOpen = (isOpen || scopeSection.isOpen);
                            currentSection.isOpen = scopeSection.isOpen;
                            $scope.sections[scopeIndex++] = currentSection;
                        } else if (isEqual && isEmpty) {
                            // Delete section
                            $scope.sections.splice(scopeIndex, 1);
                        } else if (!isEmpty) {
                            // Add section
                            $scope.sections.splice(scopeIndex++, 0, currentSection);
                        }

                    }

                    if (!isOpen && $scope.sections.length > 0) {
                        $timeout(function() {
                            $scope.sections[0].isOpen = true;
                        }, 1);
                    }

                } else {
                    $scope.sections = sections.filter(function(section) {
                        return !_.isEmpty(section.attributes);
                    });
                    if ($scope.sections.length > 0) {
                        $timeout(function() {
                            $scope.sections[0].isOpen = true;
                        }, 1);
                    }
                }
                $rootScope.translateAllSections($scope.sections);

                $scope.initAttributesForSearch(true);
                $scope.sectionToFocus = $scope.sectionToFocus || $scope.currentSection;
                $scope.attributeToFocus = $scope.attributeToFocus || $scope.currentAttribute;

            }

            $scope.categorySelected = function() {
                var deferred = $q.defer();

                var selectedCategoryName = $scope.item.category__;
                $rootScope.lastSelectedCategory = $scope.item.category__;

                // Load and setup layout sections, if either primary key is set, or category and layout is known
                if (!_.isEmpty(selectedCategoryName) &&
                        $rootScope.dataModel.hasCategory(selectedCategoryName) &&
                        $rootScope.dataModel.hasLayout($scope.currentLayout, $scope.item)) {

                    // Load default item, if item is new
                    if ($scope.isNewItem && !$scope.isCopiedItem) {
                        queueManager.getQueue('validateItemQueue').pauseProcessor();

                        var defaultItem = $rootScope.dataModel.getDefaultItem(selectedCategoryName);
                        if (defaultItem) {
                            // we need to use this verbose extend syntax here, to ensure that the item-data
                            // is not overwritten by the default-item
                            $scope.item = angular.extend({}, angular.copy(defaultItem), $scope.item);

                            loadAdditionalSectionAttributes($scope.currentLayout, $scope.item).then(function() {
                                setCategories(selectedCategoryName);
                                deferred.resolve();
                            });

                            queueManager.getQueue('validateItemQueue').continueProcessor();
                        } else {
                            DefaultItemResource.get({
                                category: selectedCategoryName
                            }, function(defaultItem) {
                                $rootScope.dataModel.setDefaultItem(selectedCategoryName, defaultItem.toJSON());
                                $scope.item = angular.extend({}, defaultItem.toJSON(), $scope.item);

                                loadAdditionalSectionAttributes($scope.currentLayout, $scope.item).then(function() {
                                    setCategories(selectedCategoryName);
                                    deferred.resolve();
                                });

                                queueManager.getQueue('validateItemQueue').continueProcessor();
                            });
                        }
                    } else {
                        loadAdditionalSectionAttributes($scope.currentLayout, $scope.item).then(function() {
                            setCategories(selectedCategoryName);
                            deferred.resolve();
                        });
                    }

                } else {
                    $scope.sections = [];
                    setCategories(selectedCategoryName).then(function() {
                        deferred.resolve();
                    });
                }

                return deferred.promise;

            };

            function setCategories(selectedCategoryName) {

                var callCategorySelected = false;

                if (_.isEmpty($scope.allCategories)) {
                    var actions = ($scope.isCopiedItem || $scope.isNewItem) ? 'create' : 'edit';
                    _.forEach($rootScope.dataModel.allCategories(), function(category) {
                        if (selectedCategoryName == category.name ||
                                Auth.hasAnyPermission(Auth.OBJECT_TYPE_ITEMS, actions, {category__: category.name})) {
                            $scope.allCategories.push(category);
                        }
                    });
                    $rootScope.translateAllCategories($scope.allCategories);
                }

                if ($scope.allCategories.length == 1 && !_.isEmpty($scope.allCategories[0].name)) {
                    $scope.showCategorySection = false;
                    if (_.isNil($scope.item.category__)) {
                        $scope.item.category__ = $scope.allCategories[0].name;
                        callCategorySelected = true;
                    }
                }

                if (callCategorySelected) {
                    return $scope.categorySelected();
                } else {

                    // Set the editable state only once
                    if (_.isNil($scope.isItemEditable)) {
                        $scope.isItemEditable = $scope.isCopiedItem || $scope.isNewItem || $scope.hasEditItemRights();
                    }

                    return $q.resolve();
                }

            }

            $scope.hasEditItemRights = function() {
                return Auth.hasRights('edit.items', 'create.items', 'copy.items') && Auth.hasItemPermission('edit', $scope.item);
            };

            $scope.saveItemForTask = function(item, taskOptions) {
                $scope.saveItem(item, function(result) {
                    result.taskOptions = taskOptions;
                    executeCloseEditor(result);
                });
            };

            $scope.saveWithoutClose = function(item) {
                $scope.saveItem(item, function(result) {
                    $scope.item = result.item;
                    stopItemChangesQueues();
                    startItemChangesQueues();
                });
            };

            $scope.saveItem = function(item, callback) {

                if (!_.isEmpty($scope.localValidations)) {
                    var attributeLabels = $scope.getAttributeLabelsForLocalValidations();
                    growl.error($translate.instant('LOCAL_VALIDATION.CAN_NOT_SAVE_ITEM') + attributeLabels);
                    return;
                }

                if (_.isNil(callback)) {
                    callback = function(result) {
                        executeCloseEditor(result);
                    };
                }

                if (!$scope.isItemEditable) {
                    executeSave(null, callback);
                    return;
                }

                var itemModified = $rootScope.hasItemModified();
                if (itemModified) {

                    $scope.saving = true;

                    var itemToSave = $rootScope.cleanupItem(item);

                    // For compatibility with old data models that are not using the group-in-group functionality,
                    // we have to make sure that "Object" or "Array" like values of "String" attributes are not stored as such,
                    // but rather "stringified" before storing!
                    itemToSave = $rootScope.stringifyDeepValues(itemToSave);

                    if ($scope.vm.desiredAuditStatus != itemToSave.audited__) {
                        ItemResource.audit({
                                primaryKey: itemToSave.primaryKey__,
                                status: $scope.vm.desiredAuditStatus
                            },
                            function(response) {
                                itemToSave.audited__ = $scope.vm.desiredAuditStatus;
                                executeSave(itemToSave, callback);
                            },
                            function(error) {
                                growl.error('SAVE_ERROR_MESSAGE');
                            }
                        );
                    } else {
                        executeSave(itemToSave, callback);
                    }

                } else {
                    executeSave(null, callback);
                }

            };

            function executeSave(itemToSave, callback) {

                var result;

                if ($scope.checkItemModified()) {
                    var isUnique = $scope.isCopiedItem || $scope.isNewItem;
                    ItemResource.save({
                            merge: true,
                            unique: isUnique
                        },
                        itemToSave,
                        function(item) {

                            growl.success("SAVE_SUCCESS_MESSAGE");
                            if (isUnique) {
                                $rootScope.$broadcast('updateOrganizationUsageLimit', 1);
                            }

                            result = {
                                action: 'itemChanged',
                                item: item
                            };
                            callback(result);

                        },
                        function(response) {
                            $scope.status = response.status;
                            if (response.data.message) {
                                $scope.errorMessage = $translate.instant(response.data.message, response.data.parameters);
                                growl.error($scope.errorMessage);
                            } else {
                                growl.error("SAVE_ERROR_MESSAGE");
                            }
                        }
                    ).$promise.finally(function() {
                        $scope.saving = false;
                    });
                } else if (!_.isNil(itemToSave)) {
                    growl.success("SAVE_SUCCESS_MESSAGE");
                    result = {
                        action: 'itemChanged',
                        item: itemToSave
                    };
                    callback(result);
                    $scope.saving = false;
                } else {
                    result = {
                        action: 'itemUnchanged',
                        item: null
                    };
                    callback(result);
                    $scope.saving = false;
                }

            }

            $scope.$on('$routeChangeStart', function($event, next, current) {
                if (next.originalPath === "/exports") {
                    $modalInstance.dismiss({});
                }
            });

            $scope.closeEditor = function(result) {
                $scope.resetEditor();

                if ($scope.closing) {
                    return;
                }
                $scope.closing = true;
                result = result || {action: 'cancel'};

                if (!$scope.isItemEditable || !$rootScope.hasItemModified()) {
                    executeCloseEditor(result);
                    $scope.closing = false;
                } else {
                    var confirmDialog = $dialogs.confirm('MODAL.CONFIRM_HEADER', 'MODAL.DISMISS_WITH_UNSAVED_CHANGES');
                    confirmDialog.result.then(function() {
                        executeCloseEditor(result);
                    }).finally(function() {
                        $scope.closing = false;
                    });
                }

            };

            function executeCloseEditor(result) {

                close();

                $document.unbind("keydown keypress");

                if (result.action === 'cancel') {
                    $modalInstance.dismiss(result);
                } else {
                    $modalInstance.close(result);

                    if (params.forceCloseTask && result.taskOptions  && result.taskOptions.closeTask) {
                        var task = params.task;
                        task.taskStatus = 'FINISHED';
                        TaskResource.save({
                            taskId: task.id
                        }, task, function(response) {
                            growl.success("TASK.SAVE_TASK_SUCCESS_MESSAGE");
                            $location.path("/tasks").search('');
                        });
                    }
                }

                $rootScope.$broadcast('itemEditorClosed', result);

            }

            var closed = false;

            function close() {

                if (closed) {
                    return;
                }

                stopItemChangesQueues();
                hopscotch.getCalloutManager().removeAllCallouts();

                LocalStorage.removeItem(TEMP_ITEM);

                if (!itemChangedEventRegistered) {
                    ChannelService.unregister(ChannelService.ITEM_CHANGED_EVENT);
                }

                if (itemEditedEventRegistered && !_.isNil(itemEditedEventIdentifier)) {
                    ChannelService.unregister(ChannelService.ITEM_EDITED_EVENT, itemEditedEventIdentifier, $scope.isItemEditable);
                }

                closed = true;

            }

            $scope.$on('$destroy', function() {
                close();
            });

            function getItem(primaryKey) {
                loadItem(ItemResource.get, {
                    'primaryKey': primaryKey,
                    'validate': LOAD_WITH_VALIDATIONS
                });
            }

            function copyItem(primaryKey) {
                loadItem(ItemResource.copy, {
                    'primaryKey': primaryKey,
                    'validate': LOAD_WITH_VALIDATIONS
                });
            }

            function loadItem(itemResourceMethod, itemResourceParams) {

                $scope.displayEditor = false;
                itemResourceMethod(itemResourceParams,
                    function(itemResult) {

                        stopItemChangesQueues();

                        $scope.item = itemResult;
                        if (itemResourceParams.validate) {
                            $scope.attributeStates = itemResult.__attributeStates__ || {};
                            delete itemResult.__attributeStates__;
                            $scope.validations = itemResult.__validations__ || {};
                            delete itemResult.__validations__;
                        } else {
                            $scope.attributeStates = {};
                            $scope.validations = {};
                        }

                        setValidationStatus($scope.item.validation_dirty__ ? VALIDATION_STATUS_IS_DIRTY : null);

                        $scope.vm.desiredAuditStatus = $scope.item.audited__;
                        $scope.originalItem = angular.copy($scope.item);
                        var layout = $rootScope.dataModel.layout($scope.currentLayout);
                        var hideCategorySection = _.get(layout,'layoutOptions.hideCategorySection');
                        $scope.showCategorySection = !hideCategorySection || _.isNil($scope.item.category__);
                        var itemEditorService =  $rootScope.getService(CUSTOM_ITEM_SERVICE);
                        $scope.enableAudit = !itemEditorService || itemEditorService.enableAuditedButton($scope.item, $rootScope.user, $rootScope.organization);

                        if ($rootScope.isDataModelLoaded) {
                            itemLoaded();
                        } else {
                            $scope.$on('dataModelLoaded', itemLoaded);
                        }

                    },
                    function(errorResponse) {
                        displayEditor();
                        $scope.status = errorResponse.status;
                        growl.error("ERROR_LOADING_ITEM");
                    }
                );

            }

            function itemLoaded() {
                $scope.categorySelected()
                    .then(startItemChangesQueues)
                    .then(initHierarchyTabs)
                    .finally(displayEditor);
            }

            function setValidationStatus(validationStatus) {
                if (_.isNil(validationStatus)) {
                    var levels = _.countBy(_.flatten(_.values($scope.validations)), 'level' );
                    $scope.validationErrors = _.toInteger(levels.Failure) + _.toInteger(levels.Error);
                    $scope.validationWarnings = _.toInteger(levels.Warning);
                    if ($scope.validationErrors > 0) {
                        validationStatus = VALIDATION_STATUS_IS_NOT_COMPLIANT;
                    } else {
                        validationStatus = VALIDATION_STATUS_IS_COMPLIANT;
                    }
                }
                $scope.validationStatus = validationStatus;
            }

            // Function to call when item changes are transmitted
            function processTransmitItem(itemChanges, item) {

                // Remove changed attribute from list of remote edited fields
                angular.forEach(itemChanges, function(value, attribute) {
                    remoteEditedAttributes.removeByContent(attribute);
                });

                var data = {
                    item: itemChanges,
                    userId: $rootScope.user.userId
                };
                var broadcast = ChannelService.broadcast(ChannelService.ITEM_EDITED_EVENT, item.primaryKey__, data);

                return broadcast;
            }

            // Dummy method for old custom templates
            $scope.validateAttributes = function() {};

            $scope.undoChanges = [];
            $scope.redoChanges = [];
            $scope.undoChange = false;

            function undoRedoChange(popArr, pushArr, key) {
                var lastChange = popArr[popArr.length-1];
                if (!_.isNil(lastChange) && !_.isEmpty(lastChange.attrs)) {
                    _.forEach(lastChange.attrs, function(attrName) {
                        var attr = _.find($scope.allAttributes, {name : attrName}) || {};
                        if ( (attr.typeName == "Collection" || attr.typeName == "MultiDimensional" || attr.typeName == "MultiReference") && _.isNil(lastChange[key][attrName])) {
                            var row = _.get($scope.gridApiMap["item['"+ attrName +"']"],'grid.rows[0]');
                            if (!_.isNil(row)) {
                                $scope.removeRow(row,attrName);
                            }
                        } else {
                            $scope.item[attrName] = lastChange[key][attrName];
                            if (attr.typeName === 'SingleReference') {
                                var lastAttrChange = lastChange[key][attrName];
                                // If the last change value is defined, add it using the single reference UI filter
                                if (lastAttrChange) {
                                    $scope.addSingleReference({ primaryKey__: lastAttrChange }, attr, null, 'item["' + attr.name + '"]');
                                }
                                // If the last change value is undefined, update the temporary model for typeahead
                                else {
                                    $scope.temporaryModelForTypeahead[attrName] = lastAttrChange;
                                }
                            }
                        }
                        $scope.undoChange = true;
                    });
                } else {
                    $scope.undoChange = false;
                }
                pushArr.push(lastChange);
                popArr.pop();
            }

            $scope.undo = function() {
                undoRedoChange($scope.undoChanges,$scope.redoChanges,"redo");
            };

            $scope.redo = function() {
                undoRedoChange($scope.redoChanges,$scope.undoChanges,"undo");
            };

            // Function to call when item changes are validated
            function processValidateItem(itemChanges, item, previousItem) {
                var attributeNames;
                var change = {};
                if (Object.keys(previousItem).length === 0) {
                    // 'previousItem' is empty on the first 'watch' call, so validate the complete item compared to original item
                    attributeNames = null;
                    previousItem = $scope.originalItem;
                } else if (itemChanges.category__) {
                    // validate complete item when category is changed
                    attributeNames = null;
                } else {
                    // validate changed attributes only
                    attributeNames = Object.keys(itemChanges);
                }
                // Checking and removing new option if it has empty content for dimensional types
                if (attributeNames) {
                    _.forEach(attributeNames, function(attributeName) {
                        var attribute = _.find($scope.allAttributes, function(attr) {
                            return attr.name === attributeName;
                        });
                        if (!$rootScope.isEmpty(attribute) && attribute.typeName == 'Dimensional' && attribute.options && item[attributeName]) {
                            _.forEach(item[attributeName], function(lan,key) {
                                var option = _.find(attribute.options, {key: key});
                                // remove if it is new option and empty
                                if (!option && $rootScope.isEmpty(item[attributeName][key])) {
                                    delete item[attributeName][key];
                                }
                            });
                        }
                        if ($scope.undoChange && !$rootScope.isEmpty(attribute) && attribute.typeName == 'AdditionalCategory' && item[attributeName]) {
                            $scope.updateAdditionalCategoryAttributes($scope.item, attributeName);
                        }
                    });
                }
                if (!_.isEmpty(attributeNames) && !$scope.undoChange) {
                    change.attrs = attributeNames;
                    change.redo = {};
                    change.undo = {};
                    _.forEach(attributeNames, function(attrName) {
                        change.redo[attrName] = previousItem[attrName];
                        change.undo[attrName] = item[attrName];
                    });
                    $scope.undoChanges.push(change);
                    $scope.redoChanges = [];
                }
                $scope.undoChange = false;

                setValidationStatus(VALIDATION_STATUS_IS_RUNNING);
                var oldAttributeStates = angular.copy($scope.attributeStates);
                var validateItem =
                    ValidateItemService.validateItem($scope,
                                                     $scope.item,
                                                     item,
                                                     previousItem,
                                                     $scope.attributeStates,
                                                     $scope.validations,
                                                     attributeNames,
                                                     function() {
                                                        // Pause the validation queue once the validation result is back
                                                        // so we don't register any change due to the validation response (calculated attributes)
                                                        queueManager.getQueue('validateItemQueue').pauseProcessor();
                                                     });
                validateItem.$promise.then(function() {
                    setValidationStatus(null);

                    // Skip a digest cycle to make sure that the item changes due to
                    // the validation calculated attributes (if any) are flushed into the queue
                    $timeout(function() {
                        queueManager.getQueue('validateItemQueue').continueProcessor();
                    }, 0);

                    // Re-render grids to show/hide any member attributes of complex types that
                    // has a state change due to the validation
                    $scope.rerenderGrid(oldAttributeStates,$scope.attributeStates);
                }, function() {
                    setValidationStatus(VALIDATION_STATUS_IS_DIRTY);
                    queueManager.getQueue('validateItemQueue').pauseProcessor();
                });

                return validateItem.$promise;
            }

            function removeReadonlyAttributes(item) {

                // Remove readonly attribute values
                _.forEach(item, function(value, attributeName) {
                    var attribute = $rootScope.dataModel.attribute(attributeName);
                    if ($scope.isAttributeReadonly(attribute || attributeName)) {
                        delete item[attributeName];
                    }
                });

                return item;
            }

            function preprocessItemChanges(item) {
                item = angular.copy(item);
                item = removeReadonlyAttributes(item);
                return item;
            }

            // Create queues for transmitting, validating and storing changes
            var queueManager = new ItemChangesQueueManager();
            queueManager.createQueue('transmitItemQueue', 300, $scope.isNewItem ? null : processTransmitItem, null, preprocessItemChanges);
            queueManager.createQueue('validateItemQueue', 300, processValidateItem);
            queueManager.createQueue('storeItemQueue', null, null, null, preprocessItemChanges);

            function startTransmitItemChangesQueue() {
                queueManager.startQueue('transmitItemQueue', $scope.item);
            }

            function stopTransmitItemChangesQueue() {
                queueManager.stopQueue('transmitItemQueue');
            }

            function startItemChangesQueues() {

                startTransmitItemChangesQueue();
                queueManager.startQueue('validateItemQueue', LOAD_WITH_VALIDATIONS ? $scope.item : {}); // Starting the queue on an empty item also validates the complete item
                if ($scope.isCopiedItem) {
                    queueManager.startQueue('storeItemQueue', {}); // Starting the queue on a copied item checks all copied values
                } else {
                    queueManager.startQueue('storeItemQueue', $rootScope.cleanupItem($scope.item));
                }
                queueManager.startWatch($scope, 'item', function(item) {
                    // Watch on changes of cleaned up item (i.e. remove transient values of multi-ref attributes)
                    return $rootScope.cleanupItem(item);
                });

                // Pause validation, until item is no longer 'dirty'
                if ($scope.validationStatus == VALIDATION_STATUS_IS_DIRTY) {
                    queueManager.getQueue('validateItemQueue').pauseProcessor();
                }

                var cachedItem = JSON.parse(LocalStorage.getItem(TEMP_ITEM));
                $log.debug("cachedItem:", cachedItem);
                if (!_.isEmpty(cachedItem) &&
                    cachedItem.primaryKey__ == params.itemPrimaryKey) {
                    angular.extend($scope.item, cachedItem);
                }
                LocalStorage.removeItem(TEMP_ITEM);

            }

            function stopItemChangesQueues() {
                queueManager.stop();
            }

            $rootScope.hasItemModified = function() {
                return $scope.checkItemModified() || $scope.vm.desiredAuditStatus != $scope.item.audited__;
            };

            $scope.checkItemModified = function() {
                if (!$scope.isItemEditable) {
                    return false;
                }
                // Check if any changes were made
                var storeItemQueue = queueManager.getQueue('storeItemQueue');
                return storeItemQueue.hasQueuedItemChanges();
            };

            var itemEditedEventIdentifier = null;

            if (!_.isNil(params.itemPrimaryKey)) {
                itemEditedEventIdentifier = params.itemPrimaryKey;
                getItem(params.itemPrimaryKey);
            } else if (!_.isNil(params.origPrimaryKey)) {
                copyItem(params.origPrimaryKey);
            } else {

                $scope.item = new ItemResource();
                $scope.originalItem = {};
                $scope.attributeStates = {};
                $scope.validations = {};

                // Start the changes queues before setting the category, so that a modification is detected right away
                startItemChangesQueues();

                $scope.item.category__ = params.category || $rootScope.lastSelectedCategory;
                $scope.categorySelected().finally(displayEditor);

            }

            // Watch on 'displayEditor' variable for a delayed initialization of events.
            // Otherwise $scope.$on('destroy') can be faster when reloading or switching to another item!
            if ($scope.$watch) {
                var deregisterWatchDisplayEditor = $scope.$watch('displayEditor', function(newValue, oldValue) {
                    if (newValue && newValue != oldValue) {
                        init();
                        deregisterWatchDisplayEditor();
                    }
                });
            }

            var itemChangedEventRegistered = false;
            var itemEditedEventRegistered = false;

            function init() {

                $document.bind("keydown keypress", function (evt) {
                    if (evt.which === 27 && !$rootScope.additionalModalOpen) {
                        var form = evt.currentTarget.activeElement.form;
                        if (_.isNil(form) || form.name !== 'inputForm') {
                            $scope.closeEditor();
                        }
                    }
                });

                itemChangedEventRegistered = ChannelService.isRegistered(ChannelService.ITEM_CHANGED_EVENT);
                if (!itemChangedEventRegistered) {
                    ChannelService.register(ChannelService.ITEM_CHANGED_EVENT);
                }

                if (!_.isNil(itemEditedEventIdentifier)) {

                    // Register to edited events and only listen to users connecting/disconnecting if item editable
                    ChannelService.register(ChannelService.ITEM_EDITED_EVENT, itemEditedEventIdentifier, $scope.isItemEditable);

                    // Only set list of editing users, if item is editable
                    if ($scope.isItemEditable) {
                        ChannelService.getRegistered(ChannelService.ITEM_EDITED_EVENT, itemEditedEventIdentifier).then(function(response) {
                            $log.debug("getRegistered(ChannelService.ITEM_EDITED_EVENT,", itemEditedEventIdentifier, "):", response);
                            $scope.connectedUserIds = _.filter(response, function(userId) {
                                return userId != $rootScope.user.id;
                            });
                        });
                    }

                    itemEditedEventRegistered = true;
                }

            }

            function checkModel(model, attributeName) {
                if (_.isEqual("item[a.name]", model)) {
                    model = "item['" + attributeName + "']";
                } else if (!_.includes(model, "'")) {
                    model = model.replace(/\[/g, "['");
                    model = model.replace(/\]/g, "']");
                    model = model.replace(/\'\d+\'/g, function (x) {
                        return x.replace(/\'/g, "");
                    });
                }
                return model;
            }

            $scope.gridOptionsMap = {}; // Contains all the columnDefs on first/initial render (excluding columns of hidden attribute members), the map
                                        // key here is the attribute path, ex. `item["collection"]` or `item["group"][0]["collection"]`.
            $scope.gridApiMap = {};     // Contains all Angular-UI-Grid instances which are already rendered and are/were in viewport, the map
                                        // key here is the attribute path, ex. `item["collection"]` or `item["group"][0]["collection"]`.
            $scope.gridCache = {};      // Contains all the columnDefs (including hidden columns of attribute members), the cache key
                                        // here is the attribute name
            $scope.showGrid = {};

            $scope.initGridOptions = function(attributeDefinition, item, name, model, config) {

                var memberAttributes = $rootScope.dataModel.getMemberAttributes(attributeDefinition, true);

                // Nothing to show
                if (_.isEmpty(memberAttributes)){
                    return false;
                }

                model = checkModel(model, name);

                // We can only filter the grid-like attributes when in read-only state so
                // we don't trigger a data change in the editor after the filtration.
                var doFilter = $scope.isAttributeReadonly(attributeDefinition) &&
                        !_.includes(attributeDefinition.typeName, 'MultiReference');
                var data = $rootScope.getItemGridData(attributeDefinition, item, name, model, doFilter, $scope);
                var modelEval = $parse(model);
                modelEval.assign($scope, data);

                var isNewItem = _.isNil(item.primaryKey__);

                var options = $rootScope.getOptions($scope, attributeDefinition, memberAttributes, data, config, model);

                // We keep a copy of the columnDefs in case we need to force a re-render of the grid, while
                // keeping one instance of the cached columns. For example in a group, the attribute columns
                // would be defined multiple times, and we only need one.
                if (_.isNil($scope.gridCache[attributeDefinition.name])) {
                    $scope.gridCache[attributeDefinition.name] = angular.copy(options);
                }

                // Now we can remove the 'hidden' member attributes
                var visibleMemberAttributes = _.filter(memberAttributes, function (memberAttribute) {
                    return !$scope.isAttributeHidden(memberAttribute);
                });

                options.columnDefs =
                    _.filter(options.columnDefs, function(columnDef) {

                        if (_.isNil(columnDef.attribute)) {
                            // non-datamodel based columns, ex: 'Actions' column
                            return true;
                        }

                        return _.includes(visibleMemberAttributes, columnDef.attribute && columnDef.attribute.name);
                    });

                if (data && data.length > 0) {
                    if (data.length >= 10) {
                        options.minRowsToShow = 10;
                    } else {
                        options.minRowsToShow = data.length + 1;
                    }
                } else {
                    options.minRowsToShow = (attributeDefinition.defaultValue && isNewItem) ? attributeDefinition.defaultValue.length : 1;
                }

                options.baseOnRegisterApi = options.onRegisterApi || undefined;

                options.onRegisterApi = function(gridApi) {
                    // If onRegisterApi is already defined, apply it and then apply any further extensions.
                    if (!_.isNil(options.baseOnRegisterApi)) {
                        options.baseOnRegisterApi(gridApi);
                    }

                    if (gridApi.cellNav) {
                        gridApi.cellNav.on.viewPortKeyDown($scope, function($event, rowCol) {
                            if ($event.keyCode === 32) {
                                $event.preventDefault();

                                var uiGridCell = gridApi.cellNav.getFocusedCell();
                                var id = "#cell-" + uiGridCell.col.grid.renderContainers.body.visibleRowCache.indexOf(uiGridCell.row) + "-" + uiGridCell.col.grid.renderContainers.body.visibleColumnCache.indexOf(uiGridCell.col);
                                var htmlCell = angular.element('body').find(id);
                                var checkbox = htmlCell.find('.checkbox-inline');

                                if (checkbox.length > 0 ) {
                                    $scope.$emit('laxGridStartCellEdit', uiGridCell.row, uiGridCell.col);
                                    htmlCell.children().focus();
                                    checkbox.select();
                                    checkbox.trigger('click');
                                }
                            }
                        });
                    }

                    $scope.gridApiMap[model] = gridApi;

                    var gridStateKey = GRID_STATE_KEY_PREFIX + model;
                    $rootScope.initGridState($scope, gridApi, gridStateKey, null, options.columnDefs);

                };

                $scope.gridOptionsMap[model] = options;

                $timeout(function() {
                    model = checkModel(model, name);
                    $scope.showGrid[model] = true;
                }, 1);

                return true;
            };

            $scope.getGridData = function(model, attribute) {
                model = checkModel(model, attribute.name);
                return $scope.gridOptionsMap[model];
            };

            $scope.getShowGrid = function(model, attribute) {
                model = checkModel(model, attribute.name);
                return $scope.showGrid[model];
            };

            //Clears the sorting configuration for the specified grid model and updates the grid state.
            $scope.removeSort = function (model) {
                var gridStateKey = GRID_STATE_KEY_PREFIX + model;
                var gridState = JSON.parse(localStorage.getItem(gridStateKey));

                if (!gridState || !gridState.columns) {
                    return;
                }

                gridState.columns.forEach(function (column) {
                    if (column.sort) {
                        column.sort = {};
                    }
                });

                localStorage.setItem(gridStateKey, angular.toJson(gridState));

                var gridApi = $scope.gridApiMap[model];
                if (!gridApi) {
                    return;
                }

                gridApi.grid.columns.forEach(function (column) {
                    column.sort = undefined;
                });

                gridApi.grid.refresh();

                $timeout(function () {
                    $scope.gridOptionsMap[model].columnDefs = angular.copy($scope.gridOptionsMap[model].columnDefs);
                }, 0);
            };

            $scope.addElementToTableModel = function(attributeDefinition, model) {
                model = checkModel(model, attributeDefinition.name);

                $scope.removeSort(model);

                var modelEval = $parse(model);
                var rows = modelEval($scope)|| [];
                rows.push({});
                if (rows.length < 10) {
                    $scope.gridOptionsMap[model].minRowsToShow++;
                }

                $timeout(function() {
                    var gridApi = $scope.gridApiMap[model];
                    if (!gridApi) {
                        return;
                    }
                    var lastElement = rows[rows.length - 1];
                    var columnDefsElement = $scope.gridOptionsMap[model].columnDefs[0];
                    if (gridApi.cellNav) {
                        gridApi.cellNav.scrollToFocus(lastElement, columnDefsElement);
                    } else {
                        gridApi.core.scrollTo(lastElement, columnDefsElement);
                    }
                }, 0);
            };

            $scope.removeSingleDimension = function(attributeDefinition, model, dimension) {
                if (!_.isNil(attributeDefinition) && !_.isNil(model) && !_.isNil(dimension)) {
                    model = checkModel(model, attributeDefinition.name);
                    var modelEval = $parse(model);
                    var attribute = modelEval($scope);
                    if (attribute) {
                        delete attribute[dimension.key];
                        if (_.isEmpty(attribute)){
                            clearAttribute(attributeDefinition.name, attribute);
                        }
                    }
                }
            };

            $scope.removeAttributeValueConfirmation = function(attr, cbk) {
                var modalInstance = $modal.open({
                    templateUrl: 'tpl/confirm-delete-attribute-value-modal.tpl.html',
                    controller: 'ModalInstanceCtrl',
                    backdrop: true,
                    resolve: {
                        data: function() {
                            var data = {
                                attribute: attr,
                                scope : $scope
                            };
                            return data;
                        }
                    }
                });
                modalInstance.result.then(function(result) {
                    if (result) {
                        cbk();
                    }
                });
            };

            $scope.removeRow = function(row, attributeName) {
                var model = row.grid.options.data;
                model = checkModel(model, attributeName);
                var modelEval = $parse(model);

                if (!$scope.disableDeleteConfirmation) {
                    var attr = $rootScope.dataModel.attribute(attributeName);
                    $scope.removeAttributeValueConfirmation(attr,function() {

                        var rows = modelEval($scope);
                        if (rows.length > 0 && rows.length <= 10) {
                            $scope.gridOptionsMap[model].minRowsToShow--;
                        }
                        _.remove(rows, row.entity);
                    });
                } else {
                    var rows = modelEval($scope);
                    if (rows.length > 0 && rows.length <= 10) {
                        $scope.gridOptionsMap[model].minRowsToShow--;
                    }
                    _.remove(rows, row.entity);
                }
            };

            $scope.uploadFileForItem = function(attributeName, sectionName, type, model) {
                var config = {};
                switch (type) {
                    case "Image":
                        config = {
                            autoUpload: true,
                            filters: ['imageFilter'],
                            accept: 'image/jpeg,image/gif,image/png,image/svg+xml,image/bmp,image/tiff,image/webp,application/pdf'
                        };
                        var maxFileSize = $scope.dataModel.sectionAttributeParam($scope.currentLayout, sectionName, attributeName, 'maxFileSize');
                        if (maxFileSize) {
                            config.filters.push('maxFileSizeFilter');
                            config.maxFileSize = maxFileSize;
                        }
                        break;
                    case "Document":
                        config = {
                            autoUpload: true
                        };
                        break;
                }

                var attribute = $scope.dataModel.attribute(attributeName);
                var publicAsset = !(attribute && attribute.params.confidential === true);
                model = checkModel(model, attributeName);

                var uploadUrl = AssetFoldersService.getDefaultUploadUrl();
                return angular.extend({
                    url: uploadUrl,
                    reset: true,
                    formData: [],
                    noNameEncoding: true,
                    useFilename: true,
                    disableDragAndDrop: attribute.params.disableDragAndDrop,
                    uploadComplete: function(response) {
                        var modelEval = $parse(model);
                        if (publicAsset) {
                            return AssetFoldersService.getPublicAssetUrlAsync(response, response.path)
                                .then(function(linkedPublicAsset) {
                                    modelEval.assign($scope, linkedPublicAsset.publicAssetUrl);
                                    return linkedPublicAsset; // Return the public asset URL
                                })
                                .catch(function(error) {
                                    $log.error(error);
                                });
                        } else {
                            modelEval.assign($scope, response.privateAssetUrl);
                            return response; // Resolve with the private asset URL
                        }
                    },
                    onErrorItem: function(item, response, status, headers) {
                        $log.error(response);

                        if (!_.isNil(response.errorCode)) {
                            growl.error(response.message, { variables : { name: item.file.name } });
                        } else {
                            growl.error("ASSET_FOLDER.ERROR_OCCURRED");
                        }
                    },
                    onFileSelected: function(linkedAsset, linkedAssetPath, done) {
                        if (publicAsset) {
                            AssetFoldersService.getPublicAssetUrlAsync(linkedAsset, linkedAssetPath)
                                .then(function(linkedPublicAsset) {
                                    done(linkedPublicAsset);
                                }, function(error) {
                                    $log.error(error);
                                });
                        } else {
                            done(linkedAsset);
                        }
                    }
                }, config);
            };

            $scope.getSectionValidationMessages = function(section) {

                section.validationCount = 0;
                section.validationErrorCount = 0;
                section.validationWarningCount = 0;

                var validationErrors = "";
                var validationFailures = "";
                var validationWarnings = "";
                angular.forEach(section.attributes, function(attribute) {
                    var validations = $scope.validations[attribute.name];
                    if (validations && !$scope.isAttributeHidden(attribute) ) {
                        angular.forEach(validations, function(validation) {
                            var validationName = attribute.name + ":" + validation.name;
                            var validationLabel = (validation.translatedLabel || $rootScope.translateValidationLabel(validationName));
                            var message = "\n<li>" + validationLabel + "</li>";
                            if(attribute.typeName == "Group"){
                                message = _.replace(message, attribute.name, validation.path[validation.path.length-1]);
                            }
                            section.validationCount++;
                            if (validation.level === 'Error') {
                                validationErrors += message;
                                section.validationErrorCount++;
                            } else if (validation.level === 'Failure') {
                                validationFailures += message;
                                section.validationErrorCount++;
                            } else if (validation.level === 'Warning') {
                                validationWarnings += message;
                                section.validationWarningCount++;
                            }
                        });
                    }
                });

                return "<ul>" + validationErrors + validationFailures + validationWarnings + "\n</ul>";
            };

            $scope.hasValidations = function(section) {

                var hasValidations = false;

                // use for loop since there is no break for angular.forEach()
                for (var i = 0; i < section.attributes.length; i++) {
                    var attribute = section.attributes[i];
                    validations = $scope.validations[attribute.name];
                    if (validations) {
                        if (validations.length !== 0) {
                            hasValidations = true;
                            // exit loop and show validation icon
                            break;
                        }
                    }
                }

                return hasValidations;
            };

            $scope.hasOnlyValidationWarnings = function(attributeName) {
                var attributeValidations = $scope.validations[attributeName];
                var hasWarningsOnly = false;

                if (attributeValidations) {
                    for (var i = 0; i < attributeValidations.length; i++) {
                        var validation = attributeValidations[i];
                        if (!hasWarningsOnly && validation.level === 'Warning') {
                            hasWarningsOnly = true;
                        } else if (validation.level === 'Failure' || validation.level === 'Error') {
                            hasWarningsOnly = false;
                            break;
                        }
                    }
                }

                return hasWarningsOnly;
            };

            $scope.currentSection = null;
            $scope.currentAttribute = null;

            $scope.focusSection = function(section) {

                $log.debug("focusSection: section=", section,
                           ", $scope.sectionToFocus=", $scope.sectionToFocus,
                           ", $scope.attributeToFocus=", $scope.attributeToFocus);

                if ($scope.isSectionOpen(section) && $scope.attributeToFocus) {
                    $scope.currentSection = section.name;
                    focusOn($scope.attributeToFocus);
                }

                $scope.sectionToFocus = null;
                $scope.attributeToFocus = null;

            };

            $scope.isSectionOpen = function(section) {

                // Don't focus, if all attributes are hidden
                if ($scope.isAllAttributesHidden(section.attributes)) {
                    return false;
                }

                var hasSectionToFocus = !_.isNil($scope.sectionToFocus);
                var hasAttributeToFocus = !_.isNil($scope.attributeToFocus);

                if (!hasSectionToFocus && !hasAttributeToFocus) {

                    // Find first non hidden attribute, if available
                    attribute = _.find(section.attributes, function(attribute) {
                        if (!$scope.isAttributeHidden(attribute)) {
                            return attribute;
                        }
                    });

                    if (attribute) {
                        $scope.sectionToFocus = section.name;
                        $scope.attributeToFocus = attribute.name;
                        return true;
                    }

                } else if (!hasSectionToFocus && hasAttributeToFocus) {

                    // Check if section includes attribute to focus and attribute is not hidden
                    attribute = _.find(section.attributes, function(attribute) {
                        if (attribute.name == $scope.attributeToFocus && !$scope.isAttributeHidden(attribute.name)) {
                            return true;
                        }
                    });

                    if (attribute) {
                        $scope.sectionToFocus = section.name;
                        return true;
                    }

                } else if (hasSectionToFocus && section.name == $scope.sectionToFocus) {

                    // Find first non hidden attribute, if available
                    attribute = _.find(section.attributes, function(attribute) {
                        if ((!hasAttributeToFocus || attribute.name == $scope.attributeToFocus) && !$scope.isAttributeHidden(attribute)) {
                            return attribute;
                        }
                    });

                    if (attribute) {
                        $scope.attributeToFocus = attribute.name;
                        return true;
                    }

                }

                return false;
            };

            $scope.focusAttribute = function(attributeName) {

                // Find all sections containing the attribute to focus on,
                // then either take the first open section, if available,
                // or simply the first non open section
                var sections = _.filter($scope.sections, function(section) {
                    return _.some(section.attributes, function (attribute) {
                        if (_.isEqual(attribute.name, attributeName)) {
                            return true;
                        }
                        // Also check for memberAttributes
                        return _.some(attribute.memberAttributes, function (memberAttribute) {
                            if (_.isEqual(memberAttribute.name, attributeName)) {
                                // focus on parent attribute
                                attributeName = attribute.name;
                                return true;
                            }
                        });
                    });
                });
                var section = _.find(sections, function(section) {
                    if (section.isOpen) {
                        return true;
                    }
                });
                section = section || sections[0];

                if (section) {

                    if (section.isOpen) {
                        focusOn(attributeName);
                        $scope.sectionToFocus = null;
                        $scope.attributeToFocus = null;
                    } else {
                        $scope.sectionToFocus = section.name;
                        $scope.attributeToFocus = attributeName;
                        section.isOpen = true;
                    }

                    // Some attribute type templates don't set the url, so simply set it again
                    $scope.setAttributeUrl(attributeName);

                }

            };

            function focusOn(attributeName) {
                var elemCount = angular.element(document).find("#" + attributeName).length;
                $timeout(function () {
                    if (elemCount === 0 && $scope.sectionLoaded) {
                        focusOn(attributeName);
                    } else {
                        $rootScope.$broadcast('focusOn', attributeName);
                    }
                }, 200);
            }

            $scope.sectionLoaded = false;

            $scope.setSectionLoaded = function(value) {
                $scope.sectionLoaded = value;
            };

            $scope.setAttributeUrl = function(attribute) {
                var url;
                if (params.origPrimaryKey) {
                    url = "/copy/" + params.origPrimaryKey;
                } else if ($scope.item.primaryKey__) {
                    url = "/edit/" + $scope.item.primaryKey__;
                } else {
                    url = "/edit/new";
                }
                url += "/attribute/" + attribute;
                $scope.currentAttribute = attribute;
                $location.displayUrl(url);
            };

            $scope.removeCallouts = function() {
                $scope.$broadcast("closeAllUiSelect");
                hopscotch.getCalloutManager().removeAllCallouts();
            };

            $scope.resetEditor = function() {
                $rootScope.onShowItemReview = false;
                $rootScope.onShowHistory = false;
                $rootScope.onShowItemGrouping = false;
                $rootScope.onShowItemDetails = false;
                $scope.onEnterViewName = false;
                $scope.onAddView = false;
                $scope.onEditView = false;
                $rootScope.onShowPublicationStatus = false;
                $scope.resetHierarchyShown();
            };

            $scope.toggleItemReview = function() {
                $rootScope.onShowItemReview = !$rootScope.onShowItemReview;
            };

            $scope.getItemReviews = function() {
                $scope.showItemReviews = !$scope.showItemReviews;
                $scope.showTaskView = false;
                $scope.reviewAttributes = $rootScope.dataModel.getReviewAttributes();
                $rootScope.prepareAttributes($scope.reviewAttributes);
                SupplierReviewResource.get({primaryKey: $scope.item.primaryKey__}, {},
                    function(response) {
                        $scope.reviews = response;
                    });
            };

            $scope.showTask = function() {
                $scope.showTaskView = !$scope.showTaskView;
                if (!$scope.task.comments) {
                    TaskResource.getComments({
                        taskId: $scope.task.id
                    }, function(response) {
                        $scope.task.comments = response || [];
                    });
                }
                $scope.showItemReviews = false;
            };

            $scope.enabledItemReview = function() {
                var customService =  $rootScope.getService(CUSTOM_ITEM_REVIEW_SERVICE);
                var isEnabledByDatamodel = !customService || customService.enableItemReview($scope.item, $rootScope.user, $rootScope.organization);
                return (!_.isNil($scope.item.channel__) || !_.isNil($scope.item.supplier__)) && isEnabledByDatamodel;
            };

            $scope.showItemHistory = function() {
                $scope.resetEditor();
                $rootScope.onShowHistory = !$rootScope.onShowHistory;
            };

            $scope.showPublicationStatus = function() {
                $scope.loadPublications($scope.item.primaryKey__);
                $scope.resetEditor();
                $rootScope.onShowPublicationStatus = !$rootScope.onShowPublicationStatus;
            };

            $scope.showItemGrouping = function() {
                var itemModified = $rootScope.hasItemModified();
                if (!itemModified) {
                    $scope.resetEditor();
                    $rootScope.onShowItemGrouping = !$rootScope.onShowItemGrouping;
                } else {
                    var confirmDialog = $dialogs.confirm('MODAL.CONFIRM_HEADER', 'MODAL.DISMISS_WITH_UNSAVED_CHANGES');
                    confirmDialog.result.then(function() {
                        $scope.item = $scope.originalItem;
                        $scope.resetEditor();
                        $rootScope.onShowItemGrouping = !$rootScope.onShowItemGrouping;
                    }, function() {});
                }
            };

            $scope.showItemDetails = function() {
                var itemModified = $rootScope.hasItemModified();
                if (!itemModified) {
                    $scope.resetEditor();
                    $rootScope.onShowItemDetails = !$rootScope.onShowItemDetails;
                } else {
                    var confirmDialog = $dialogs.confirm('MODAL.CONFIRM_HEADER', 'MODAL.DISMISS_WITH_UNSAVED_CHANGES');
                    confirmDialog.result.then(function() {
                        $scope.item = $scope.originalItem;
                        $scope.resetEditor();

                        $rootScope.onShowItemDetails = !$rootScope.onShowItemDetails;
                    }, function() {});
                }
            };

            $scope.promptAndOpenNewItem = function(item, showItemPrimaryKey) {
                result = {action: 'itemChanged'};
                if (!$scope.isItemEditable || !$rootScope.hasItemModified()) {
                    result.showItemPrimaryKey = showItemPrimaryKey;
                    executeCloseEditor(result);
                } else {
                    var confirmDialog = $dialogs.confirm('MODAL.CONFIRM_HEADER', 'MODAL.DISMISS_WITH_UNSAVED_CHANGES');
                    confirmDialog.result.then(function() {
                            result.showItemPrimaryKey = showItemPrimaryKey;
                            executeCloseEditor(result);},
                        function() {
                            openEditorWithItem(item, showItemPrimaryKey);
                        }
                    );
                }

            };

            $scope.auditItem = function(item) {
                if ($scope.vm.desiredAuditStatus !== null) {
                    $scope.vm.desiredAuditStatus = !$scope.vm.desiredAuditStatus;
                } else {
                    $scope.vm.desiredAuditStatus = !item.audited__;
                }
            };

            $scope.loadPublications = function(primaryKey) {
                if (_.isNil($scope.itemPublications)) {
                    ItemResource.getPublications({primaryKey: primaryKey}, function(publications) {
                        $scope.itemPublications = _.filter(publications, { publishedStatus : "PUBLISHED" });
                        loadPublicationDestinations().then(function(data) {
                            $scope.isLoading = false;
                            _.forEach($scope.itemPublications, function(publication) {
                                publication.organization = _.find($scope.organizations, { organizationId : publication.destinationId });
                                // Check the destinationType and/or the subDestination (which only exists for communication channel publications!)
                                if (publication.destinationType == 'IN_PLATFORM' ||
                                (_.isNil(publication.destinationType) && _.isNil(publication.subDestination))) {
                                    // load organization for publication.destinationId
                                    publication.organization = _.find($scope.organizations, { organizationId : publication.destinationId });
                                } else if (publication.destinationType == 'COMMUNICATION_CHANNEL' ||
                                (_.isNil(publication.destinationType) && !_.isNil(publication.subDestination))) {
                                    // load communicationChannel for publication.destinationId
                                    publication.communicationChannel = _.find($scope.communicationChannels, function(ch) {
                                        return (!publication.subDestination || (publication.subDestination == ch.subDestinationKey)) &&  ch.id == publication.destinationId;
                                    });
                                }

                                // If still nothing is set, first try to load organization and then communication channel
                                if (_.isNil(publication.organzation) && _.isNil(publication.communicationChannel)) {
                                    // load organization for publication.destinationId,
                                    // if no organization found, load communicationChannel for publication.destinationId
                                    publication.organization = _.find($scope.organizations, { organizationId : publication.destinationId });
                                    if (_.isNil(publication.organization)) {
                                        publication.communicationChannel = _.find($scope.communicationChannels, function(ch) {
                                            return (!publication.subDestination || (publication.subDestination == ch.subDestinationKey)) &&  ch.id == publication.destinationId;
                                        });
                                    }
                                }
                            });
                            $scope.itemPublicationsByOrg = _.filter($scope.itemPublications, function(p) {
                                return !!p.organization;
                            });
                            $scope.itemPublicationsByChannel = _.filter($scope.itemPublications, function(p) {
                                return !!p.communicationChannel;
                            });
                        });
                    });
                }
            };

            $scope.plans = $rootScope.getPreparedCommunicationPlans();

            function loadPublicationDestinations() {
                return CommunicationChannelService.loadPublicationDestinations().then(function(result) {
                    $scope.communicationChannels = result.communicationChannels;
                    $scope.organizations = result.organizations;
                });
            }

            $scope.getItemPosition = function(item) {
                return _.indexOf(params.primaryKeys, item.primaryKey__) + 1;
            };

            $scope.getItemCount = function() {
                return _.size(params.primaryKeys);
            };

            $scope.openNextItem = function(item) {
                openEditorWithItem(item, getNextItemKey(item));
            };

            $scope.openPreviousItem = function(item) {
                openEditorWithItem(item, getPreviousItemKey(item));
            };

            function getNextItemKey(item) {
                var index = _.indexOf(params.primaryKeys, item.primaryKey__);
                var length = $scope.getItemCount();
                return index < length ? params.primaryKeys[index + 1] : null;
            }

            function getPreviousItemKey(item) {
                var index = _.indexOf(params.primaryKeys, item.primaryKey__);
                var length = $scope.getItemCount();
                return index > 0 ? params.primaryKeys[index - 1] : null;
            }

            function openEditorWithItem(item, showItemPrimaryKey) {
                $scope.saveItem(item, function(result) {
                    result.showItemPrimaryKey = showItemPrimaryKey;
                    executeCloseEditor(result);
                });
            }

            $scope.formatNavItemIndex = function(item) {

                var itemPosition = _.toString($scope.getItemPosition(item));
                var itemCount = _.toString($scope.getItemCount());

                // Prepend with "0" depending to maximum "length" of the numbers
                var length = _.max([itemPosition.length, itemCount.length]);
                var format = _.padStart(itemPosition, length, "0") + " | " + _.padStart(itemCount, length, "0");

                return format;
            };

            $scope.showItemHierarchy = function(hierarchy) {
                $log.debug(hierarchy + " is clicked");
                $scope.resetEditor();

                $scope.itemHierarchyStatus.forEach(function(value, key, map) {
                    if (key == hierarchy) {
                        $scope.itemHierarchyStatus.set(key, true);

                    } else {
                        $scope.itemHierarchyStatus.set(key, false);
                    }
                });
            };

            // called after item is loaded
            function initHierarchyTabs() {
                var categoryInfo = $rootScope.dataModel.category($scope.item.category__);
                if (categoryInfo && categoryInfo.hierarchies != null){

                    $scope.itemHierarchyStatus = new Map();

                    for (i = 0; i < categoryInfo.hierarchies.length; i++) {
                        $scope.itemHierarchyStatus.set(categoryInfo.hierarchies[i],false);
                    }
                    $scope.itemHierarchies = categoryInfo.hierarchies;
                }
            }

            $scope.isHierarchyShown = function(hierarchy) {
                if (hierarchy != null) {
                    return $scope.itemHierarchyStatus.get(hierarchy);
                } else {
                    var shouldHierarchyBeShown = false;
                    $scope.itemHierarchyStatus.forEach(function(value, key, map) {
                        if (value == true){
                            shouldHierarchyBeShown = true;
                        }
                    });
                    return shouldHierarchyBeShown;
                }
            };

            $scope.resetHierarchyShown = function() {
                $scope.itemHierarchyStatus.forEach(function(value, key, map) {
                    $scope.itemHierarchyStatus.set(key, false);
                });
            };

            $scope.currentHierarchy = function() {
                var currentHierarchyName = "";
                $scope.itemHierarchyStatus.forEach(function(value, key, map) {
                    if (value == true){
                        currentHierarchyName = key;
                    }
                });
                return currentHierarchyName;
            };

            $scope.getChangedAttributeStates = function (oldAttributeStates, newAttributeStates) {

                var changedAttributeStates = [];
                var keysOldAttributeStates = Object.keys(oldAttributeStates);
                var keysNewAttributeStates = Object.keys(newAttributeStates);

                var allKeysAttributeStates = _.union(keysOldAttributeStates, keysNewAttributeStates);

                _.forEach(allKeysAttributeStates, function (key) {

                    // checking for the attributes whose state is changed from hidden to unhidden
                    if (_.includes(keysOldAttributeStates, key) && !_.includes(keysNewAttributeStates, key)) {
                        changedAttributeStates.push(key);
                    }
                    // checking for the attributes whose state is changed from unhidden to hidden
                    if (!_.includes(keysOldAttributeStates, key) && _.includes(keysNewAttributeStates, key)) {
                        changedAttributeStates.push(key);
                    }
                });

                return changedAttributeStates;

            };

            $scope.rerenderGrid = function (oldAttributeStates, newAttributeStates) {

                // Got all the attributes whose state has been changed
                var attributesWithChangedState = $scope.getChangedAttributeStates(oldAttributeStates, newAttributeStates);

                var allDataModelAttributes = $scope.dataModel.allAttributes();
                _.forEach(allDataModelAttributes, function (datamodelAttribute) {
                    // this is only for the sub attribute of the UI grid
                    if (_.includes(['Collection', 'MultiDimensional'], datamodelAttribute.typeName)) {
                        var memberAttributes = $rootScope.dataModel.getMemberAttributes(datamodelAttribute);
                        _.forEach(memberAttributes, function (memberAttribute) {
                            if (_.includes(attributesWithChangedState, memberAttribute.name)) {
                                $scope.rerenderSpecificGrid(memberAttribute.name, datamodelAttribute.name);
                            }
                        });
                    }

                });

            };

            $scope.getCachedGridColumns = function(attributeName) {

                var cacheKey = attributeName;

                // Attribute might be a member attribute of a composite one, its cache key
                // might be a path, so we look for these ones too
                var alternateMapKey = "[" + cacheKey + "]";

                var cachedGrid = _.find($scope.gridCache, function(value, key) {
                    return key === cacheKey || _.includes(key, alternateMapKey);
                });

                if (!_.isNil(cachedGrid)) {
                    return cachedGrid.columnDefs;
                } else {
                    return null;
                }
            };

            $scope.getGridInstances = function(attributeName) {

                var mapKey = attributeName;

                // Attribute might be a member attribute of a composite one, its map key
                // might be a path, so we look for these ones too
                var alternateMapKey = "['" + mapKey + "']";

                return _.reduce($scope.gridApiMap, function(mapHits, value, key) {

                    if (key === mapKey || _.includes(key, alternateMapKey)) {
                        mapHits.push(value);
                    }

                    return mapHits;
                }, []);
            };

            $scope.rerenderSpecificGrid = function (changedMemberAttribute, changedAttribute) {

                var changedGridInstances = $scope.getGridInstances(changedAttribute);

                if (_.isEmpty(changedGridInstances)) {
                    // Intended grid state change is not rendered yet, skipping.
                    return;
                }

                // We should either (create new column definitions for the appropriate grid) or
                // we should get the current column defs and remove the one hidden or
                // create the ones that should be shown
                var hidden = $scope.isAttributeHidden(changedMemberAttribute);
                if (hidden) {

                    // We hide all the occurrences of changed attribute, for example when
                    // its composite is repeated in a group.
                    _.forEach(changedGridInstances, function(gridInstance) {

                        var changedGridColumnDefs = gridInstance.grid.options.columnDefs;

                        var columnIndex = _.findIndex(changedGridColumnDefs, { attribute: { name: changedMemberAttribute } });
                        changedGridColumnDefs.splice(columnIndex, 1);

                    });

                } else {

                    // We show (or make sure it's shown) all the occurrences of changed attribute, for example when
                    // its composite is repeated in a group.
                    _.forEach(changedGridInstances, function(gridInstance) {

                        var changedGridColumnDefs = gridInstance.grid.options.columnDefs;
                        var changedColumnDef = _.find(changedGridColumnDefs, { attribute: { name: changedMemberAttribute } });

                        // If the column for the attribute already exists, we should not duplicate it
                        if (!_.isNil(changedColumnDef)) {
                            return;
                        }

                        var cachedGridColumns = $scope.getCachedGridColumns(changedAttribute);

                        if (_.isNil(cachedGridColumns)) {
                            $log.error('Could not find grid cache {} in order to update member attribute {} state', changedAttribute, changedMemberAttribute);
                            return;
                        }

                        var columnDef = _.find(cachedGridColumns, function (columnDef) {
                            if (!_.isNil(columnDef.attribute) && columnDef.attribute.name === changedMemberAttribute) {
                                return true;
                            } else {
                                return false;
                            }
                        });

                        changedGridColumnDefs.push(columnDef);

                    });
                }
            };

            $scope.$on('addLocalValidation', function(event, eventData) {
                if (_.isEmpty(eventData.attributeErrors.errors)) {
                    delete $scope.localValidations[eventData.path];
                } else {
                    $scope.localValidations[eventData.path] = eventData.attributeErrors;
                }
            });

            $scope.getAttributeLabelsForLocalValidations = function() {
                var attributeLabels = '';
                _.forEach($scope.localValidations, function(attributeErrors) {

                    var attribute = attributeErrors.attribute;
                    var translatedLabel = attribute.translatedLabel || $rootScope.translateAttribute(attribute);

                    attributeLabels += '<li>' + translatedLabel + '</li>';
                });
                return attributeLabels;
            };

        });
